//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// AppMain - The main application page (Home Page) for the InteractMenu Application
//              Version 1.006 - March 25, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
import React from 'react';
import { Navigate } from 'react-router-dom' // Superset of react-router
import { SvgIcon } from '@progress/kendo-react-common';
import { Input } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons';  
import { Popup } from '@progress/kendo-react-popup';
import { FloatingLabel } from '@progress/kendo-react-labels'
import accessOMAPI from './CommonCode.js';
import { CallOM,LogOnDefault,mainLogon,UserRegister,QueryPing,LogOn,LogOff,GetPWCToken,ChangePassword,GetLocationInfo,displayMessage,displayError,getCookie,setCookie,deleteCookie,xorEncode,trace,appendScript,CTYP,TYP } from './CommonCode.js';
import { ENAppCnfg, ENLayoutCnfg } from './CommonInfo.js';
import GoogleLogin from 'react-google-login';
import { BrowserMultiFormatReader } from '@zxing/library'; // Barcode Scanner
import { withRouter } from './withRouter';
import { SessionInfo } from './App';

{/* Menu SVG Icons */ }
const about = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path fill="current" d="M487.7,13.5H24.3c-5.9,0-10.8,4.8-10.8,10.8v463.4c0,5.9,4.8,10.8,10.8,10.8h463.4c5.9,0,10.8-4.8,10.8-10.8
	V24.3C498.5,18.4,493.6,13.5,487.7,13.5z M278.6,432.4c-7.4,6.6-15.9,9.8-25.6,9.8c-10,0-18.7-3.2-26.2-9.7
	c-7.5-6.5-11.2-15.5-11.2-27.2c0-10.3,3.6-19,10.8-26.1c7.2-7,16.1-10.6,26.6-10.6c10.3,0,19,3.5,26.1,10.6
	c7,7.1,10.6,15.7,10.6,26.1C289.7,416.8,286,425.8,278.6,432.4z M370.5,204.6c-5.7,10.5-12.4,19.6-20.2,27.2
	c-7.8,7.6-21.8,20.5-41.9,38.5c-5.6,5.1-10,9.6-13.4,13.4c-3.4,3.9-5.9,7.4-7.5,10.6c-1.6,3.2-2.9,6.4-3.8,9.6
	c-0.9,3.2-2.3,8.8-4.1,16.9c-3.1,17.1-12.9,25.6-29.3,25.6c-8.5,0-15.7-2.8-21.5-8.4c-5.8-5.6-8.7-13.9-8.7-24.8
	c0-13.8,2.1-25.7,6.4-35.8c4.3-10.1,9.9-18.9,17-26.6c7-7.6,16.6-16.7,28.5-27.2c10.5-9.2,18.1-16.1,22.8-20.8
	c4.7-4.7,8.6-9.9,11.8-15.6c3.2-5.7,4.8-12,4.8-18.7c0-13.1-4.9-24.2-14.6-33.2c-9.8-9-22.3-13.5-37.8-13.5
	c-18,0-31.3,4.6-39.9,13.7c-8.5,9.1-15.7,22.5-21.6,40.2c-5.6,18.5-16.2,27.8-31.7,27.8c-9.2,0-16.9-3.2-23.2-9.7
	c-6.3-6.5-9.5-13.5-9.5-21c0-15.6,5-31.4,15-47.4c10-16,24.6-29.2,43.8-39.7C211,75,233.4,69.8,259,69.8c23.8,0,44.8,4.4,63,13.2
	c18.2,8.8,32.3,20.7,42.2,35.8c9.9,15.1,14.9,31.5,14.9,49.2C379,181.9,376.2,194.1,370.5,204.6z"/>
</SvgIcon>
const contacts = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M259.4,262.2L456,66.1c3.8-3.8,1.1-10.3-4.3-10.3H58.6c-5.4,0-8.1,6.5-4.3,10.3l196.6,196.1
	  C253.3,264.5,257.1,264.5,259.4,262.2z"/>
  <path d="M13,450.9c0,15.5,12.6,28.1,28.1,28.1h427.7c15.5,0,28.1-12.6,28.1-28.1v-19.7c0-1.8-0.7-3.5-2-4.8
		L328.4,259.9c-2.6-2.6-6.9-2.6-9.5,0l-34.5,34.5c-7.8,7.8-18.2,12.1-29.3,12.1s-21.4-4.3-29.3-12.1l-34.5-34.5
		c-2.6-2.6-6.9-2.6-9.5,0L15,426.8c-1.3,1.3-2,3-2,4.8V450.9z"/>
  <path d="M154,222.4L23.5,91.9C19.6,88,13,90.8,13,96.3v261c0,5.5,6.6,8.2,10.5,4.4L154,231.2
		C156.5,228.8,156.5,224.9,154,222.4z"/>
  <path d="M497,357.3v-261c0-5.4-6.5-8.1-10.3-4.3L356.2,222.5c-2.4,2.4-2.4,6.2,0,8.5l130.5,130.5
		C490.5,365.4,497,362.7,497,357.3z"/>
</SvgIcon>
//const dashboard = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
//  <path d="M256,40.1C114.7,40.1,0.2,154.7,0.2,295.9c0,60.4,21,116,56,159.7h399.6c35-43.8,56-99.3,56-159.7
//	C511.8,154.7,397.3,40.1,256,40.1z M256,91.8c14.1,0,25.6,11.5,25.6,25.6S270.1,143,256,143s-25.6-11.5-25.6-25.6
//	S241.9,91.8,256,91.8z M76.8,322.2c-14.1,0-25.6-11.5-25.6-25.6c0-14.1,11.5-25.6,25.6-25.6s25.6,11.5,25.6,25.6
//	C102.4,310.8,90.9,322.2,76.8,322.2z M129.3,195.5c-14.1,0-25.6-11.5-25.6-25.6s11.5-25.6,25.6-25.6c14.1,0,25.6,11.5,25.6,25.6
//	S143.4,195.5,129.3,195.5z M351.4,247.4L300,298.8c2.3,5.4,3.5,11.3,3.5,17.5c0,25-20.3,45.3-45.3,45.3s-45.3-20.3-45.3-45.3
//	s20.3-45.3,45.3-45.3c7.8,0,15.2,2,21.7,5.5l50.4-50.4c4.7-4.7,12.3-4.7,17,0l4.2,4.2C356.1,235,356.1,242.7,351.4,247.4z
//	 M383,195.5c-14.1,0-25.6-11.5-25.6-25.6s11.5-25.6,25.6-25.6s25.6,11.5,25.6,25.6S397.2,195.5,383,195.5z M435.2,322.2
//	c-14.1,0-25.6-11.5-25.6-25.6c0-14.1,11.5-25.6,25.6-25.6s25.6,11.5,25.6,25.6C460.8,310.8,449.3,322.2,435.2,322.2z"/>
//</SvgIcon>
const cancel = <SvgIcon viewBox="0 0 686 686" size="default" themeColor="inherit">
  <line fill="current" x1="75.6" y1="75.6" x2="410.8" y2="410.8" />
	<path fill="current" d="M410.8,455.8c-11.5,0-23-4.4-31.8-13.2L43.8,107.4c-17.6-17.6-17.6-46.1,0-63.6c17.6-17.6,46.1-17.6,63.6,0
		l335.1,335.1c17.6,17.6,17.6,46.1,0,63.6C433.8,451.4,422.3,455.8,410.8,455.8z"/>
  <line fill="current" x1="410.8" y1="75.6" x2="75.6" y2="410.8" />
  <path fill="current" d="M75.6,455.8c-11.5,0-23-4.4-31.8-13.2c-17.6-17.6-17.6-46.1,0-63.6L378.9,43.8c17.6-17.6,46.1-17.6,63.6,0
		c17.6,17.6,17.6,46.1,0,63.6L107.4,442.6C98.6,451.4,87.1,455.8,75.6,455.8z"/>
</SvgIcon>
const home = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M401.1,443H100.7v40.8c0,11.1,9,20.2,20.2,20.2h270.3c11.1,0,20.2-9,20.2-20.2V443H401.1z" />
  <path fill="current" d="M507.5,171.7L464.2,38.8c-3.5-14-16.1-23.8-30.6-23.8H78.4c-14.5,0-27.1,9.8-30.6,23.8L4.5,171.7
				C4.2,173,4,174.3,4,175.5c0,43.4,33.5,78.7,74.8,78.7c24,0,45.4-11.9,59.1-30.5c13.7,18.5,35.1,30.5,59.1,30.5
				s45.4-11.9,59.1-30.5c13.7,18.5,35.1,30.5,59.1,30.5c24,0,45.4-11.9,59.1-30.5c13.7,18.5,35.1,30.5,59.1,30.5
				c41.3,0,74.8-35.3,74.8-78.7C508,174.3,507.8,173,507.5,171.7z"/>
  <path fill="current" d="M433.2,285.8c-21.4,0-41.8-6.6-59.1-18.5c-34.5,24-83.7,24-118.1,0c-34.5,24-83.7,24-118.1,0
				c-17.2,12-37.6,18.5-59.1,18.5c-15.5,0-30-3.6-43.3-9.8v189.5c0,17.4,14.1,31.5,31.5,31.5h126V390.7c0-10.9,8.8-19.7,19.7-19.7
				h86.6c10.9,0,19.7,8.8,19.7,19.7V497h126c17.4,0,31.5-14.1,31.5-31.5V276C463.2,282.2,448.6,285.8,433.2,285.8z"/>
</SvgIcon>
const kitchen = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path fill="current" d="M401.1,443H100.7v40.8c0,11.1,9,20.2,20.2,20.2h270.3c11.1,0,20.2-9,20.2-20.2V443H401.1z" />
  <path fill="current" d="M502.7,208.6c-5.7-51.9-49.3-91.4-101.5-92H401c-3.7,0-7.3,0.2-11,0.6c-22.5-70.8-98-109.9-168.8-87.5
	c-42.3,13.4-75.3,46.9-88.1,89.4c-7.3-1.6-14.8-2.4-22.3-2.4C53.9,117.3,8.2,163.9,8.7,220.9c0.5,52.2,40,95.9,92,101.5v100.4h310.7
	V322.3C467.9,316.1,508.8,265.2,502.7,208.6z M198.3,371.9c0,6.6-5.4,12-12,12h-10.5c-6.6,0-12-5.4-12-12V271.4c0-6.6,5.4-12,12-12
	h10.5c6.6,0,12,5.4,12,12V371.9z M273.2,371.9c0,6.6-5.4,12-12,12h-10.5c-6.6,0-12-5.4-12-12V271.4c0-6.6,5.4-12,12-12h10.5
	c6.6,0,12,5.4,12,12V371.9z M348.2,371.9c0,6.6-5.4,12-12,12h-10.5c-6.6,0-12-5.4-12-12V271.4c0-6.6,5.4-12,12-12h10.5
	c6.6,0,12,5.4,12,12V371.9z"/>
</SvgIcon>
const logout = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path fill="current" d="M357.7,122.1V36.5c0-13.6-11.2-24.8-24.8-24.8H26.8C13.2,11.6,2,22.8,2,36.5v16.1V376v11.2
		c0,9.1,5.4,17.8,13.2,21.9l188.6,97.6c8.3,4.1,18.2-1.7,18.2-11.2v-95.1h110.8c13.7,0,24.8-11.2,24.8-24.8V239.9h-49.6v98.4
		c0,7-5.4,12.4-12.4,12.4h-73.2V223.4v-80.7v-2.5c0-9.1-5.4-17.8-13.2-21.9L98,61.3h197.7c7,0,12.4,5.4,12.4,12.4v48.8h49.6V122.1z"/>
  <path fill="current" d="M421.4,81.5l80.7,80.7c10.3,10.3,10.3,25.6,0,36l-80.7,80.7c-10.3,10.3-26.1,10.8-36.4,0.4
		c-9.9-9.9-8.7-26.5,0.8-36.4l36.4-36h-141c-7,0-14.1-2.9-18.6-8.3c-11.6-12.4-8.7-34.3,6.2-42.6c3.7-2.1,8.3-3.3,12.4-3.3h141
		c0,0-36-36-36.4-36c-9.5-9.5-10.8-26.5-0.8-36C394.9,70.8,411,71.2,421.4,81.5z"/>
</SvgIcon>
//const manager = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
//  <path d="M314.4,35H287l-3.5-9.7C279.4,13.7,268.3,5.8,256,5.8s-23.4,7.8-27.5,19.5L225,35h-27.4
//			c-16.1,0-29.2,13.1-29.2,29.2v14.6c0,8.1,6.5,14.6,14.6,14.6h146c8.1,0,14.6-6.5,14.6-14.6V64.2C343.6,48.1,330.5,35,314.4,35z"/>
//  <path d="M444.9,64.2h-72.1v14.6c0,24.2-19.6,43.8-43.8,43.8H183c-24.2,0-43.8-19.6-43.8-43.8V64.2H67.1
//	c-17.1,0-30.9,13.8-30.9,30.9v378.1c0,17,13.9,30.9,30.9,30.9h375.8c18.2,0,32.9-14.7,32.9-32.9V95.2
//	C475.8,78.1,461.9,64.2,444.9,64.2z M415.5,378.7c0,10.1-8.2,18.3-18.3,18.3h-35.9c-8.3,16.3-25.2,27.5-44.7,27.5
//	c-19.5,0-36.4-11.2-44.7-27.5H114.8c-10.1,0-18.3-8.2-18.3-18.3v-8.6c0-10.1,8.2-18.3,18.3-18.3h157.1c8.3-16.3,25.2-27.5,44.7-27.5
//	s36.4,11.2,44.7,27.5h35.9c10.1,0,18.3,8.2,18.3,18.3V378.7z M415.5,243.7c0,10.1-8.2,18.3-18.3,18.3H244.3
//	c-8.3,16.3-25.2,27.5-44.7,27.5c-19.5,0-36.4-11.2-44.7-27.5h-40.1c-10.1,0-18.3-8.2-18.3-18.3v-8.6c0-10.1,8.2-18.3,18.3-18.3h40.1
//	c8.3-16.3,25.2-27.5,44.7-27.5s36.4,11.2,44.7,27.5h152.9c10.1,0,18.3,8.2,18.3,18.3V243.7z"/>
//</SvgIcon>
const products = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M314.4,35H287l-3.5-9.7C279.4,13.7,268.3,5.8,256,5.8s-23.4,7.8-27.5,19.5L225,35h-27.4
			c-16.1,0-29.2,13.1-29.2,29.2v14.6c0,8.1,6.5,14.6,14.6,14.6h146c8.1,0,14.6-6.5,14.6-14.6V64.2C343.6,48.1,330.5,35,314.4,35z"/>
  <path d="M444.9,64.2h-72.1v14.6c0,24.2-19.6,43.8-43.8,43.8H183c-24.2,0-43.8-19.6-43.8-43.8V64.2H67.1
	c-17.1,0-30.9,13.8-30.9,30.9v378.1c0,17,13.9,30.9,30.9,30.9h375.8c18.2,0,32.9-14.7,32.9-32.9V95.2
	C475.8,78.1,461.9,64.2,444.9,64.2z M415.5,378.7c0,10.1-8.2,18.3-18.3,18.3h-35.9c-8.3,16.3-25.2,27.5-44.7,27.5
	c-19.5,0-36.4-11.2-44.7-27.5H114.8c-10.1,0-18.3-8.2-18.3-18.3v-8.6c0-10.1,8.2-18.3,18.3-18.3h157.1c8.3-16.3,25.2-27.5,44.7-27.5
	s36.4,11.2,44.7,27.5h35.9c10.1,0,18.3,8.2,18.3,18.3V378.7z M415.5,243.7c0,10.1-8.2,18.3-18.3,18.3H244.3
	c-8.3,16.3-25.2,27.5-44.7,27.5c-19.5,0-36.4-11.2-44.7-27.5h-40.1c-10.1,0-18.3-8.2-18.3-18.3v-8.6c0-10.1,8.2-18.3,18.3-18.3h40.1
	c8.3-16.3,25.2-27.5,44.7-27.5s36.4,11.2,44.7,27.5h152.9c10.1,0,18.3,8.2,18.3,18.3V243.7z"/>
</SvgIcon>
const storeLocations = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M507.5,171.7L464.2,38.8c-3.5-14-16.1-23.8-30.6-23.8H78.4c-14.5,0-27.1,9.8-30.6,23.8L4.5,171.7
			C4.2,173,4,174.3,4,175.5c0,43.4,33.5,78.7,74.8,78.7c24,0,45.4-11.9,59.1-30.5c13.7,18.5,35.1,30.5,59.1,30.5
			s45.4-11.9,59.1-30.5c13.7,18.5,35.1,30.5,59.1,30.5c24,0,45.4-11.9,59.1-30.5c13.7,18.5,35.1,30.5,59.1,30.5
			c41.3,0,74.8-35.3,74.8-78.7C508,174.3,507.8,173,507.5,171.7z"/>
  <path d="M433.2,285.8c-21.4,0-41.8-6.6-59.1-18.5c-34.5,24-83.7,24-118.1,0c-34.5,24-83.7,24-118.1,0
			c-17.2,12-37.6,18.5-59.1,18.5c-15.5,0-30-3.6-43.3-9.8v189.5c0,17.4,14.1,31.5,31.5,31.5h126V390.7c0-10.9,8.8-19.7,19.7-19.7
			h86.6c10.9,0,19.7,8.8,19.7,19.7V497h126c17.4,0,31.5-14.1,31.5-31.5V276C463.2,282.2,448.6,285.8,433.2,285.8z"/>
</SvgIcon>
const barcode = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
  <path d="M0 448V64h18v384H0zm26.857-.273V64H36v383.727h-9.143zm27.143 0V64h8.857v383.727H54zm44.857 0V64h8.857v383.727h-8.857zm36
      0V64h17.714v383.727h-17.714zm44.857 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm35.715
      0V64h18v383.727h-18zm44.857 0V64h18v383.727h-18zm35.999 0V64h18.001v383.727h-18.001zm36.001 0V64h18.001v383.727h-18.001zm26.857
      0V64h18v383.727h-18zm45.143 0V64h26.857v383.727h-26.857zm35.714 0V64h9.143v383.727H476zm18 .273V64h18v384h-18z" />
</SvgIcon>
const QRCode = <SvgIcon viewBox="0 0 512 512" size="default" themeColor="inherit">
      <path d="M0,0v233.739h233.739V0H0z M200.348,200.348H33.391V33.391h166.957V200.348z" />
      <rect x="66.783" y="66.783" width="100.174" height="100.174" />
      <path d="M278.261,0v233.739H512V0H278.261z M478.609,200.348H311.652V33.391h166.957V200.348z" />
      <rect x="345.043" y="66.783" width="100.174" height="100.174" />
      <path d="M0,278.261V512h233.739V278.261H0z M200.348,478.609H33.391V311.652h166.957V478.609z" />
      <rect x="66.783" y="345.043" width="100.174" height="100.174" />
      <polygon points="345.043,311.652 345.043,278.261 278.261,278.261 278.261,512 345.043,512 345.043,478.609 311.652,478.609 
			311.652,411.826 345.043,411.826 345.043,378.435 311.652,378.435 311.652,311.652 		"/>
      <rect x="478.609" y="278.261" width="33.391" height="33.391" />
      <polygon points="478.609,356.174 478.609,478.609 445.217,478.609 445.217,512 512,512 512,356.174 		" />
      <rect x="378.435" y="278.261" width="66.783" height="33.391" />
      <polygon points="445.217,378.435 445.217,345.043 378.435,345.043 378.435,445.217 445.217,445.217 445.217,411.826 
			411.826,411.826 411.826,378.435 		"/>
      <rect x="378.435" y="478.609" width="33.391" height="33.391" />
</SvgIcon>
// Enums
let TestVal = 0; // Referenced in mainTest3
let TestFunc;
const codeReader = new BrowserMultiFormatReader();
let bamboraCodeLoaded = false;

//----------------------------------

class AppMain extends React.Component {
  constructor(props) {
    super(props);
    SessionInfo.props = props;
    //trace(`appmain construct`);
    //console.log(props);
    SessionInfo.AppMainRef = this;      
    this.mProps = this.props;                
    //this.startUp = this.startUp.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this); 
    if (SessionInfo.AppMainPage) { // Data saved for this session
      this.state = SessionInfo.AppMainPage;
      this.currentShow = 2;
    }
    // Note - 14Sep22 - to force reload of app: window.location.reload();
    var ReturnOrder = function () { trace("Return Order"); }                                                                                                       
    //--------------------------------------------------------
    if (SessionInfo.menuAction === undefined) {
      SessionInfo.menuAction = this.onMenuItemClick;
    }
  }
  state = {
    AppNotificationID: 0,
    NotificationTypeID: 0,
    MCMessageID: 0,
    MessageID: 0,
    StoreLocationID: 0,
    CustomerID: 0,
    StoreGroupID: 0,
    NotificationDateTime: 0,
    ExpiryDateTime: 0,
    OMUserID: 0, 
    NotificationMessage: '',                                      
    bottomDisplay: 0,

    perfCounter: 0,
    runTest1: false,
    test1TimesPerSecond: 1,
    test1WaitCount: 100,
    test1Counter: 0,
    testStartTime: 0,

    activeUsers: 0,
    transactionCount: 0,
    totalTransactionCount: 0,
    strataCount: 0,
    test1SendTxCount: 0,
    test1ReturnLength: 0,

    activeCustomers: 40,
    searchValue: '',
    MessagesValue: '',
    currentTime: '',
    // Dictionary Arrays   
    NotificationTypes: [],
    StoreLocations: [],
    StoreGroups: [],

    mesgColor: "green",
    loginMessage: "",
    emailAddress: '',
    showPassword: false,
    stayLoggedOn: false,
    emReadOnly: false,
    emailAddress: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
    stayLogedOn: false,

    showChangePWPopup: false,
    showLoginPopup: false,
    showNotify: false,
    showIMPopup: false,
    scannedString: '',
  };
  ProductCategoryTableObjID = 1104137;
  ProductGroupTableObjID = 1104119;
  ProductTypeTableObjID = 1104125;
  PackagingTypeTableObjID = 1104125;
  PricingTypeTableObjID = 1104167;
  RateTypeTableObjID = 545339;
  AppNotificationTableObjID = 545857;
  StorePromotionTableObjID = 544701;
  refreshCount = 6;
  currentShow = 1;
  timeout = undefined;
  test1InProgress = false;
  mProps = undefined;
  notifyTimer = 0;
  setShowNotify = 0;
  changeToken = "";
  LoginInProgress = false;
  GoToLogin = false;

  popupOffset = { left: 20,top: 70 };
  popupIMOffset = { left: 2,top: 2 };
  showScannerOffset = { left: 15,top: 180 };

  async componentDidMount() {                       
    trace(`Menu AppMain Mounted - Logged On: ${SessionInfo.loggedOn}, entx: ${SessionInfo.entx}, href: ${SessionInfo.href}`);
    if (SessionInfo.traceDebug === true) {
      if (SessionInfo.testScriptLoad === true) // Load Script - the InteractMenuInit.js is really just for test - need to bypass this for non-payment version
        await appendScript("https://interactmenu.com/InteractMenuInit.js"); // Need to load from current source library  
      //trace(`--> Load: ${SessionInfo.href + "InteractMenuInit.js"}`);       
      //await appendScript(SessionInfo.href + "InteractMenuInit.js"); // Need to load from current source library - this cause error: Uncaught SyntaxError: Unexpected token '<' (at InteractMenuInit.js:1:1)
    }
    if (SessionInfo.debug == true && SessionInfo.ex !== '') { // Get extended control from parms (encrypted)
      trace(` ex: ${SessionInfo.ex}`);
      let CD = await accessOMAPI(135,0,0,1142,SessionInfo.ex,"LocalExec_Decrypt"); // Send OMOLW to OM Via TCP/IP - Decrypt Input
      trace(` ex CD: ${JSON.stringify(CD)}`);
      let cntrl = CD.d;    
      trace(` cntrl: ${cntrl}`);
      if (cntrl && cntrl.length > 0) {
        console.log(`parse cntrl`);
        let cntrls = JSON.parse(cntrl);
        trace(`callparams: ${JSON.stringify(cntrls)}`);
        if (cntrls.loc)
          SessionInfo.StoreLocationID = parseInt(cntrls.loc);
        if (cntrls.tab)
          SessionInfo.CurrentTableID = parseInt(cntrls.tab);
        if (cntrls.gid)
          SessionInfo.CurrentGuestID = parseInt(cntrls.gid);
        if (cntrls.seat)
          SessionInfo.CurrentSeat = cntrls.seat;
        if (cntrls.AID)
          SessionInfo.AppEntityID = parseInt(cntrls.AID);
        if (cntrls.key) {
          SessionInfo.CurrentKeyID = parseInt(cntrls.key); // Logon Key  
          SessionInfo.CheckoutToken = keyValue;
        }
        trace(`loc: ${cntrls.loc}, StoreLocationID: ${SessionInfo.StoreLocationID}, AppEntityID: ${SessionInfo.AppEntityID}`);
      }
    }
    //trace(`=====+++===> TestVal: ${TestVal}`);
    //TestScriptX(SessionInfo);       
    trace(`appLogonControl: ${SessionInfo.appLogonControl}, LogonType: ${SessionInfo.appEntityLogonType}`);
    if (SessionInfo.appLogonControl === true) { // Logon according to LogonType - this will be a configuration variable
      if (SessionInfo.appEntityLogonType === 1) {
        await this.interactLogin();
      } else if (SessionInfo.appEntityLogonType === 5) {
        // Google logon- not sure how to force this
      } else {
        await this.phoneLogin();
      }
    } else {
      if (SessionInfo.hasPhoneLogin === true) {
        document.documentElement.style.setProperty('--login-height','300px');
        SessionInfo.stayLoggedOn = true;
      }
      if (SessionInfo.CurrentKeyID && SessionInfo.CurrentKeyID != '')
        await mainLogon(keyValue);
      else if (SessionInfo.PWC != '') {
        await this.startUp(6); // Logon Password Change
      } else if (SessionInfo.loggedOn === false) {
        let button_color = getComputedStyle(document.documentElement).getPropertyValue('--button-color');
        document.documentElement.style.setProperty('--button-color',button_color);
        // Note - 15May23 - it appears that the first attempt fails because the client does not wait for the response from the server on the accessOMAPI 104
        await this.startUp(1); // This can be both - the first and the second attempt
      }
      else {
        await this.startUp(5); // Auto Logon
      }
      if (this.runMonitorProcess !== true) {
        this.runMonitorProcess = true;
        this.monitorProcess();
      }
      // Load Script - the InteractMenuInit.js is really just for test - need to bypass this for non-payment version 
      //trace(`Load InteractMenuInit from: ${SessionInfo.href}`);  
      //if (SessionInfo.testScriptLoad === true)
      //  await appendScript("https://interactmenu.com/InteractMenuInit.js"); // Need to load from current source library    
      //await appendScript(SessionInfo.href+"InteractMenuInit.js"); // Need to load from current source library 
      if (SessionInfo.traceDebug) {
        if (SessionInfo.testScriptLoad === true) { // Load Script - the InteractMenuInit.js is really just for test - need to bypass this for non-payment version
          trace(`after load InteractMenuInit`);
          trace(`---> TestVal: ${TestVal}`);
          TestScriptX(SessionInfo);
          trace(`---> AppEntityID: ${SessionInfo.AppEntityID}`);
        }
      }
      //--------------------- Bambora custom checkout --------------------
      // https://dev.na.bambora.com/docs/guides/checkout/
      // https://dev.na.bambora.com/docs/guides/custom_checkout/                                                         
      if (SessionInfo.bamboraCodeLoaded === false) {
        SessionInfo.bamboraCodeLoaded = true;
        appendScript("https://libs.na.bambora.com/customcheckout/1/customcheckout.js"); // Bambora Custom Checkout Functionality - Javascript Library - Bambora Script)
      }
    }
    if (this.GoToLogin === true) {
      console.log(`Goto Interact Phone Login`);
      this.phoneLogin();
    }
  }
  //------------------------------------------------------------------
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    if (SessionInfo.session !== '')
      SessionInfo.AppMainPage = this.state;
    if (this.timeout)
      clearTimeout(this.timeout);
    this.runMonitorProcess = false;
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------    
  runMonitorProcess = false;
  setTimer() {
    if (SessionInfo.loggedOn === true)
      this.timeout = setTimeout(this.monitorProcess.bind(this),1000); // 1 second  
    else
      this.runMonitorProcess = false;
  }
  monitorProcess = async () => { // https://medium.com/@inkdrop/a-simple-way-to-run-js-in-background-thread-on-react-native-8fff345576da
    //trace("AppMain Monitor");       
    if (this.runMonitorProcess === true && SessionInfo.loggedOn === true) {
      if (1 === 1 || document.hasFocus() === true) { // Execute monitor process 
        trace(`monitor active - AppMain`);
        //---- Ping and Notification Processing -------------
        if (SessionInfo.setShowNotify > 0 && SessionInfo.notifyMessage && SessionInfo.notifyMessage.length > 0) {
          this.setState({ notifyMessage: SessionInfo.notifyMessage });
          SessionInfo.notifyTimer = SessionInfo.notifyTime;
          this.setState({ showNotify: true }); // Show the Message  
          if (SessionInfo.notifyRing && (SessionInfo.SoundOn === true || SessionInfo.SoundOverride === true)) {
            //trace(`Play Notify Sound: ${SessionInfo.notifyRing}`);
            try {
              await SessionInfo.notifyRing.play();
            }
            catch (Ex) {
              trace(`Ring Ex: ${Ex}`);
            }
            SessionInfo.notifyRing = undefined;
            SessionInfo.SoundOverride = false;
          }
        }               
        SessionInfo.setShowNotify = 0;
        if (SessionInfo.notifyTimer > 0) {
          if (--SessionInfo.notifyTimer <= 0)
            this.setState({ showNotify: false }); 
        } else if (SessionInfo.showNotify === true)
          this.setState({ showNotify: false }); // Hide the Notify Message
        if (SessionInfo.pingCounter++ > 150) {
          SessionInfo.pingCounter = 0;
          let CD = await CallOM("PingApp",SessionInfo.StoreLocationID,SessionInfo.CustomOrderID,CTYP.STR,"");
          if (CD && CD.d && CD.d.rows) {
            //trace(`Return from PingApp - CD: ${JSON.stringify(CD)}`);
            let rows = CD.d.rows;
            for (let ix = 0; ix < rows.length; ix++) {
              let row = rows[ix];
              SetNotify(row["NotificationMessage"],row["NotificationTypeID"]);
              SessionInfo.AppNotificationID = row["AppNotificationID"];
            }
          }
        }
      }
      //---- End of Notification Processing -------------
      //----- Testing -----------------
      // Test Load Script
      //try {
      //  InitScriptX();
      //}
      //catch (e) {
      //  trace(`unable to execute InitScriptX - msg: ${e}`);
      //}
      //try {
      //  TestFunc();
      //}
      //catch (e) {
      //  trace(`unable to execute TestFunc - msg: ${e}`);
      //}
      //trace(`after InitScript exec`);
    }
    //----- Testing -----------------
    //try {
    //  InitScriptX();
    //}
    //catch (e) {
    //  trace(`unable to execute InitScriptX in monitor - msg: ${e}`);
    //}
    //try {
    //  trace(`execute custom Checkout `);
    //  var customCheckout = customcheckout();
    //  var cardNumber = customCheckout.create('card-number')
    //  cardNumber.mount('#card-number');
    //  trace(`after card-number mount `);
    //  customCheckout.on('error', function (event) {
    //    if (event.field === 'card-number') {
    //      console.log('Card number has errors: ' + JSON.stringify(event));
    //    } else if (event.field === 'cvv') {
    //      console.log('CVV has errors: ' + JSON.stringify(event));
    //    } else if (event.field === 'expiry') {
    //      console.log('Expiry has errors: ' + JSON.stringify(event));
    //    }
    //  });

    //  customCheckout.on('complete', function (event) {
    //    if (event.field === 'card-number') {
    //      console.log('Card number is complete: ' + JSON.stringify(event));
    //    } else if (event.field === 'cvv') {
    //      console.log('CVV is complete: ' + JSON.stringify(event));
    //    } else if (event.field === 'expiry') {
    //      console.log('Expiry is complete: ' + JSON.stringify(event));
    //    }
    //  });
    //}
    //catch (e) {
    //  trace(`unable to execute CustomCheckout in monitor - msg: ${e}`);
    //}
    //-----------------------------------------
    this.setTimer();
  };
  acknowledgeMessage = async () => {
    let CD = await CallOM("AcknowledgeNotification",0,SessionInfo.AppNotificationID);
    this.setState({ showNotify: false });
    SessionInfo.notifyTimer = 50;
    SessionInfo.acknowledge = false;
    this.forceUpdate();
  }
  pResponseGoogle = async (response) => {
    console.log(`Response from Google`);
    trace(response);
    trace(`profileObj: ${JSON.stringify(response.profileObj)}, token: ${JSON.stringify(response.tokenId)}`);
    let PO = response.profileObj;
    let phoneNumber = PO.googleId;
    let ix = response.tokenId.indexOf('.')
    let password = response.tokenId.substr(0,ix);
    trace(`Google Token Password: ${password}`);
    let keyToken = "GOOG" + phoneNumber; // xorEncode(phoneNumber,SessionInfo.Key);
    let m = await LogOn(PO.email,password,keyToken);
    trace(`Google Sign in - m: ${m} UserID: ${SessionInfo.OMUserID}`);
    if (m === 'ok') {
      SessionInfo.logonType = 5; // 1-logon, 3-Register, 4-token logon, 5-Google Logon, 6-Google Register, 7-SubApp Token Logon, 8-Phone Logon, 9-default logon
      trace(`---> Logon Type: ${SessionInfo.logonType}`);
      SessionInfo.defaultLogon = false; 
      SessionInfo.accountLogon = true;
      this.props.navigate(`/${SessionInfo.gotoAutoPage}`);
    }
    else { // Logon Failed - try Register
      m = await UserRegister(PO.givenName,PO.familyName,PO.email,phoneNumber,password,password,SessionInfo.registrationCode,PO.imageUrl,"GOOG");
      trace(`after Register - m: ${m}`);
      if (m === "ok") {
        let m = await LogOn(PO.email,password);
        trace(`Register - m: ${m} UserID: ${SessionInfo.OMUserID}`);
        if (m === 'ok') {
          SessionInfo.logonType = 6; // 1-logon, 3-Register, 4-token logon, 5-Google Logon, 6-Google Register, 7-SubApp Token Logon, 9-default logon
          trace(`---> Logon Type: ${SessionInfo.logonType}`);
          SessionInfo.defaultLogon = false;
          SessionInfo.accountLogon = true;
          this.props.navigate(`/${SessionInfo.gotoAutoPage}`);
        }
      }
      else {
        this.showError(`Google Login Failure for: ${PO.email}`);
      }
    }
  };
  nResponseGoogle = response => {
    trace(`Google Logon failure:`); //${response}
    console.log(response);
  };
  //------------------------------------------------------------------- 
  LF = async () => { // Logoff and restart - Log Off, Sign Off, Signoff
    trace("Call Logoff in AppMain");
    await LogOff(true);
    SessionInfo.isSubApp = false; // InteractMenu called from another App
    await this.startUp(2);
  }
  cancelMenu = async () => { // Logoff and restart
    SessionInfo.showMenu = false;
  }
  setLocation = async () => {
    trace(`Call setLocation - for: ${SessionInfo.currentPage}`);
    await SessionInfo.setLocationFunc();
  }
  //
  startUp = async (startType) => {
    trace(`====> startUP - startType: ${startType}, isSubApp: ${SessionInfo.isSubApp}`);
    if ("geolocation" in navigator) {
      //trace("geolocation Available");
      SessionInfo.LocationOn = true;
    } else {
      //trace("geolocation Not Available");
      SessionInfo.LocationOn = false;
    }
    //await TestREST();
    //-----------------------------------------------------------------------------------------------
    if (SessionInfo.isSubApp === true) { // Note - startType == 1 - InteractMenu called by another app - isSubApp
      trace(`Startup SubApp logon: ${SessionInfo.loggedOn}, StoreLocationID: ${SessionInfo.StoreLocationID}`);
      if (SessionInfo.logonToken) {
        await mainLogon(SessionInfo.logonToken);
        SessionInfo.logonType = 7; // 1-logon, 3-Register, 4-token logon, 5-Google Logon, 6-Google Register, 7-SubApp Token Logon, 9-default logon
        trace(`---> Logon Type: ${SessionInfo.logonType}`);
        SessionInfo.defaultLogon = false;
        SessionInfo.accountLogon = true;
      } else {
        trace("logonToken required for subApp")
      }
    } else if (startType === 2) {
      //trace(`showLoginPopup: ${this.state.showLoginPopup}`);
      //this.setState({ showLoginPopup: true }); // this does not work due to 
      this.props.navigate(`/Login`);
    } else if (startType === 5) { // Auto Logged On
      // Need to get the Location at this point
      trace(`Appmain loggedOn: ${SessionInfo.loggedOn}, StoreLocationID: ${SessionInfo.StoreLocationID}`);
      if (SessionInfo.loggedOn === true) {
        trace(`go to ${SessionInfo.gotoAutoPage}, orderType: ${SessionInfo.OrderType}`);
        this.props.navigate(`/${SessionInfo.gotoAutoPage}`);
      }
    } else if (startType === 6) { // Password Change
      let CD = await GetPWCToken(SessionInfo.PWC); // Get Token contents  
      trace(`StartType=6 - Return PWC - CD: ${JSON.stringify(CD)}`);
      this.changeToken = CD.d;
      this.setState({ emReadOnly: true });
      await this.setState({ emailAddress: CD.x.m });
      //trace(`emailAddress: ${CD.x.m}, ${this.state.emailAddress}`);
      // Display Change Password Popup
      this.setState({ loginMessage: '' });
      this.setState({ showChangePWPopup: true });
    } else { // Regular auto-logon  - startType = 1
      // Get Key Token from cookie if available
      let keyValue = '';
      if (SessionInfo.EntCookie === true)
        keyValue = getCookie(`IMSecure${SessionInfo.AppEntityID.toString()}ID`);
      else
        keyValue = getCookie(`IMSecureID`);
      console.log(`cookie keyValue: ${keyValue}`);
      if (keyValue && keyValue !== 'NF') { // OM returned NF token
        //trace(`IMSecureID cookie: ${value}, RoleID: ${SessionInfo.RoleID}`);
        SessionInfo.loggedOn = true;
        SessionInfo.stayLoggedOn = true;
        SessionInfo.CheckoutToken = keyValue;
        await mainLogon(keyValue);
      }
      else { // Token NOT available - goto Logon Selection Popup
        trace(`Secure ID value is not found - Log On - AppEntityID: ${SessionInfo.AppEntityID}, startType: ${startType}, logonChoice: ${SessionInfo.showLogonChoice}`);
        // 25Jan21 - This causes app failure!! - Generates react message - too early to do navigate
        //if (SessionInfo.debug === false)
        //  this.interactLogin(); // Current Default Logon Type
        //else
        //console.log(`Interact Login`);
        //await this.interactLogin();
        // await this.phoneLogin();
        //console.log(`After Interact Login`);
        // LogOnDefault works
        //let m = await QueryPing();
        //let m = await LogOnDefault();
        console.log(`Interact Login 1`);
        //SessionInfo.isPhoneLogin = false;
        //this.props.navigate(`/Login`);
        this.GoToLogin = true;
      //  if (SessionInfo.showLogonChoice == true)
      //    this.setState({ showLoginPopup: true });
      //  else
      //    await this.noLogin();
      }
    }
    trace(`InteractSuite Startup - Logon process: ${startType} Complete`);
  }
  appMainSetup = async () => { // This is done after logon and before navigation
    // Get Location and Sublocation
    trace(`--------> appMainSetup - Before LocationID: ${SessionInfo.StoreLocationID}, sub Table: ${SessionInfo.CurrentTableID}, AppEnt: ${SessionInfo.AppEntityID}`);
    if (SessionInfo.StoreLocationID <= 0) {
      let value = '';
      if (SessionInfo.AppEntityID > 0)
        value = getCookie(`IM${SessionInfo.AppEntityID.toString()}Info`);
      else
        value = getCookie(`IMInfo`);
      if (value) {
        console.log(`parse appSetup`);
        let locInfo = JSON.parse(value);
        console.log(`after parse appSetup`);
        try {
          SessionInfo.StoreLocationID = locInfo.SLoc; // Set to Cookie Value
          if (Number.isInteger(SessionInfo.StoreLocationID) === false)
            SessionInfo.StoreLocationID = 0;
          SessionInfo.CurrentTableID = locInfo.TabID; // Set to Cookie Value for tableid
          if (Number.isInteger(SessionInfo.CurrentTableID) === false)
            SessionInfo.CurrentTableID = 0;
        }
        catch (e) {
          trace(`Fail in Location ID: ${e.message}`);
        }
      }
      else {
        trace(`====> ERROR - Invalid StoreLocationID: ${SessionInfo.StoreLocationID}`);
      }
    }
    else {
      let locInfo = { "SLoc": SessionInfo.StoreLocationID,"TabID": SessionInfo.CurrentTableID };
      if (SessionInfo.AppEntityID > 0)
        setCookie(`IM${SessionInfo.AppEntityID.toString()}Info`,JSON.stringify(locInfo),30);
      else
        setCookie(`IMInfo`,JSON.stringify(locInfo),30);
    }
    if (!SessionInfo.CurrentTableID)
      SessionInfo.CurrentTableID = 0;
    trace(`App Main Setup Store Location: ${SessionInfo.StoreLocationID}, tableid: ${SessionInfo.CurrentTableID}`);
    if (SessionInfo.StoreLocationID > 0) {
      await GetLocationInfo(SessionInfo.StoreLocationID,SessionInfo.CurrentTableID);
    }  
  }
  // NOTE - this is called ONCE - not per page
  // NOTE - there are two menus - one for Order and one for Everything else
  SetupMenu = () => { // Setup Application Control Menu - Build Menu
    //trace(`--> Menu Setup - LocationID: ${SessionInfo.StoreLocationID},${SessionInfo.StoreLocationID}, sub: ${SessionInfo.CurrentTableID}, logontype: ${SessionInfo.logonType}, defaultlogon: ${SessionInfo.defaultLogon}, Perm: ${SessionInfo.PermLevel}`);
    trace(`--> Menu Setup - ApplicationTypeID: ${SessionInfo.ApplicationTypeID}, defaultLogon: ${SessionInfo.defaultLogon}, HasReceipts: ${SessionInfo.AC.HasReceipts}`);
    let addLine = false;
    let menuHeight = 110; // see .popupMenu in css
    let entcnt = 0;
    let gstcnt = 0;
    //--------------------------------------------------------
    SessionInfo.MenuControlO = [];
    if (SessionInfo.HasLandingPage === true) {
      SessionInfo.MenuControlO.push({ text: 'Home Page',expanded: false,route: 'LandingPage',key: 5,goToTab: 0,call: undefined,icon: home,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
      entcnt++;
    }    
    SessionInfo.MenuControlO.push({ text: 'Order Menu',expanded: false,route: 'OrderPage',key: 10,goToTab: 0,call: undefined,icon: storeLocations,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
    entcnt++;
    //trace(`-----> ScanPay: ${SessionInfo.AppCfg.ScanAndPay}, MenuImage: ${SessionInfo.HasMenuImage}`);
    if (SessionInfo.AppCfg.ScanAndPay === true && SessionInfo.AC.HasScanAndPay === true) { // Add Scanner - For Scan and Pay
      SessionInfo.MenuControlO.push({ text: 'Scanner',expanded: false,route: 'ScannerPage',key: 20,goToTab: 0,call: undefined,icon: barcode,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
      entcnt++;
    }
    if (SessionInfo.defaultLogon === false && SessionInfo.AC.HasReceipts === true) {
      SessionInfo.MenuControlO.push({ text: 'My Receipts',expanded: false,route: 'ReceiptsPage',key: 20,goToTab: 0,call: undefined,icon: kitchen,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
      SessionInfo.MenuControlO.push({ text: 'My Account',expanded: false,route: 'ProfilePage',key: 30,goToTab: 0,call: undefined,icon: products,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
      entcnt += 2;
    }  
    SessionInfo.MenuControlO.push({ text: 'Contact Us',expanded: false,route: 'ContactUs',key: 80,goToTab: 0,call: undefined,icon: contacts,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
    entcnt++;
    if (SessionInfo.defaultLogon === false && SessionInfo.AC.HasHelp === true) {
      SessionInfo.MenuControlO.push({ text: 'Help Info',expanded: false,route: 'AboutPage',key: 90,goToTab: 0,call: undefined,icon: about,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
      entcnt++;
    }
    if (SessionInfo.CurrentGuestID === 0 && SessionInfo.AC.HasReceipts === true) {
      SessionInfo.MenuControlO.push({ text: 'Guest QR',expanded: false,route: '',key: 99,call: this.setLocation,icon: QRCode,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
      gstcnt++;
    }
    if (SessionInfo.PermLevel > 490 && SessionInfo.AC.HasManagerPage === true) { // Manager Capability
      if (SessionInfo.PermLevel > 491) { // Manager Functions
        SessionInfo.MenuControlO.push({ text: 'Manager',expanded: false,route: 'ManagerPage',key: 90,goToTab: 0,call: undefined,icon: about,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
        entcnt++;
      }
    }
    //if (SessionInfo.defaultLogon === false && SessionInfo.AC.HasLogoff === true) {
    SessionInfo.MenuControlO.push({ text: 'Logoff',expanded: false,route: '',key: 95,call: this.LF,icon: logout,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
    entcnt++;
    SessionInfo.MenuControlO.push({ text: 'Close',expanded: false,route: '',key: 99,goToTab: 0,call: this.cancelMenu,icon: home,className: 'menuTreeItem',space: <span>&nbsp;&nbsp;</span> });
    menuHeight += entcnt * 30;
    document.documentElement.style.setProperty('--menu-heightO',`${menuHeight + gstcnt*30}px`); // Menu Height with Guest QR
    document.documentElement.style.setProperty('--menu-height',`${menuHeight}px`); // Menu Height without Guest QR
    SessionInfo.MenuControlNQR = [];
    SessionInfo.MenuControlO.forEach(item => {
      //trace(`menu item: ${JSON.stringify(item)}`);
      if (item.text != "Guest QR")
        SessionInfo.MenuControlNQR.push(item);
    });
    //trace(`menu height: ${menuHeight}, AppType: ${SessionInfo.ApplicationTypeID}`);
  }
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //
  //mainLogon = async (token) => {
  //  if (!token)
  //    token = SessionInfo.CheckoutToken;
  //  //trace(`main Logon - Keylogon with token: ${token}`);
  //  let m = await KeyLogOn(token); // Token - Decrypted: : '270	2000008	3000487	ChkOut	BLRE+jaPAp4ErnYc6SXJBNHruLSVGhHNR9JXC+hsGCc=		1	6002102			chkout	127.0.0.1' - pw: 'Checkout4#|60021023000487270'
  //  SessionInfo.keyLogon = false;
  //  //trace(`After KeyLogon msg: ${m}`);
  //  if (m === 'ok') {              
  //    trace(`Auto Signed In - UpdateCustomer - SessionID: ${SessionInfo.session}, AutoPage: ${SessionInfo.gotoAutoPage}`);
  //    // 10May24 - this is causing white screen of death - and causing omUIapi to fail - this needs be investigated
  //    //if (SessionInfo.debug == true) { // Get extended control from parms (encrypted) 
  //    //  let CD = await accessOMAPI(135,0,0,1142,SessionInfo.entx,"CheckoutMdx_UpdateCustomer"); // Test
  //    //}
  //    this.props.navigate(`/${SessionInfo.gotoAutoPage}`);
  //  }
  //  else {
  //    trace(`Unable to Login: ${m} - Deleting the Logon Token - AppEntityID: ${SessionInfo.AppEntityID}`);
  //    if (SessionInfo.EntCookie === true)
  //      deleteCookie(`IMSecure${+SessionInfo.AppEntityID.toString()}ID`); // Delete the cookie with the logon token 
  //    else
  //      deleteCookie(`IMSecureID`); // Delete the cookie with the logon token
  //    SessionInfo.loggedOn = false;
  //    this.props.navigate(`/Login`); // Logon
  //  }
  //}
  //async autoLogon() {
  //  if (SessionInfo.keyLogon === true) {
  //    trace(`menu App - autologon`);
  //    await this.mainLogon();
  //    SessionInfo.keyLogon = false;
  //  }
  //}
  interactLogin = async () => { // Button from Logon Type Popup
    console.log(`Interact Login`);
    SessionInfo.isPhoneLogin = false;
    this.props.navigate(`/Login`);
  }
  phoneLogin = async () => { // Button from Logon Type Popup  
    console.log(`Phone Login - add phone number as UserName if necessary`);
    SessionInfo.isPhoneLogin = true;
    SessionInfo.hasPhoneLogin = true;
    this.props.navigate(`/Login`);
  }
  phoneLogin2 = async () => {
    let m = await LogOnDefault();
    //LoginInProgress = false;
    trace(`Phone Sign in - m: ${m} UserID: ${SessionInfo.OMUserID}`);
    if (m === 'ok') {
      trace(`Default LOGGED ON (2) - token: ${SessionInfo.CheckoutToken} - go to ${SessionInfo.gotoAutoPage}`);
      this.props.navigate(`/${SessionInfo.gotoAutoPage}`);
    }
    else
      displayError("Sign In Error: " + m);
    this.setState({ stateHasChanged: false });
  }
  noLogin = async () => { // Provide access with NO Logon - Nologon - No Login
    let m = await LogOnDefault();
    //LoginInProgress = false;
    trace(`Sign in - m: ${m} UserID: ${SessionInfo.OMUserID}`);
    if (m === 'ok') {
      trace(`Default LOGGED ON - token: ${SessionInfo.CheckoutToken} - go to ${SessionInfo.gotoAutoPage}`); 
      this.props.navigate(`/${SessionInfo.gotoAutoPage}`);
    }
    else
      displayError("Sign In Error: " + m);
    this.setState({ stateHasChanged: false });
  }
  //------------------------------------------------------------------------------------------------------------- 
  showMessage = (msg) => {
    this.messageColor = 'blue';
    this.messageCounter = 25;
    this.setState({ NotifyMessage: msg });
    this.setState({ activateMessageBox: true });
  }
  messageClear = (msg) => {
    this.messageColor = 'blue';
    this.messageCounter = 0;
    this.setState({ NotifyMessage: '' });
    this.setState({ activateMessageBox: false });
  }
  showWarn = (msg) => {
    this.messageColor = 'orange';
    this.messageCounter = 30;
    this.setState({ NotifyMessage: msg });
    this.setState({ activateMessageBox: true });
  }
  showError = (msg) => {
    this.messageColor = 'red';
    this.messageCounter = 35;
    this.setState({ NotifyMessage: msg });
    this.setState({ activateMessageBox: true });
  }
  //------------------------------------------------------------------------------------------------------------- 
  onMenuItemClick = async () => {
    //trace(`MenuItemClick: ${JSON.stringify(SessionInfo.MenuSelect)}`);
    //trace("Menu Click Session History: " + JSON.stringify(SessionInfo.History));
    //trace("Menu Click AppMain History: " + JSON.stringify(this.AppMainProps.history)); 
    if (SessionInfo.MenuSelect.call !== undefined)
      SessionInfo.MenuSelect.call(); // This is used for direct function calls - like Logoff
    else { // Select Screen and Tab if applicable     
      trace(`currentPage: ${SessionInfo.currentPage}`);
      if (SessionInfo.currentPage === SessionInfo.MenuSelect.route) { // Selected different Tab on the current screen 
        //trace("currentPage - Tab: " + SessionInfo.MenuSelect.goToTab);
        if (SessionInfo.tabSelectFunc)
          SessionInfo.tabSelectFunc(SessionInfo.MenuSelect.goToTab);
      }
      else { // Go to the New Screen           
        trace(`goto New screen: ${SessionInfo.MenuSelect.route}`);
        this.props.navigate(`/${SessionInfo.MenuSelect.route}`);
        SessionInfo.GoToTab = SessionInfo.MenuSelect.goToTab;
      }
    }
  }
  //-------------------------------------------------------------------------------------------------------------
  searchCurrent = async (search) => {
    this.setState({ bottomDisplay: 0 });
  }
  //---
  percentFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'percent',minimumFractionDigits: 2,maximumFractionDigits: 2 }).format(value);
  moneyFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(value);    
  //--------------- Field Change Values --------------------------------
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }
  chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    this.setState({ [event.target.name]: newVal });
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    //trace("percent newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setState({ stateHasChanged: true });
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.props.name]: event.target.value.ddID });
    this.setState({ stateHasChanged: true });
  }
  chgCheckboxVal(event) {
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setState({ stateHasChanged: true });
  }
  //------------------------------------------------------------------------------------------
  setMesgColor = (color) => {
    this.setState({ mesgColor: color });
  }
  setFailCount = (failCount) => {
  }
  showMessage = (msg) => {
    this.setState({ loginMessage: msg });
    //this.setShowMsg(true);
  }
  showWarning = (msg) => {
    this.setMesgColor("orange");
    this.showMessage(`Warn: ${msg}`);
  }
  showError = (msg) => {
    this.setMesgColor("red");
    this.showMessage(`Error: ${msg}`);
  }
  setWarning = (msg) => {
    this.setMesgColor("orange");
    this.setState({ loginMessage: msg });
  }
  setError = (msg) => {
    this.setMesgColor("red");
    this.setState({ loginMessage: msg });
  }
  clearMessage = () => {
    this.setState({ loginMessage: "" });
  }
  updateEmailAddress = (event) => {
    this.setState({ emailAddress: event.target.value });
  }
  updatePassword = (event) => {
    this.setState({ password: event.target.value });
  }
  updateConfirm = (event) => {
    this.setState({ confirmPassword: event.target.value });
  }
  //const blurEmail = (event) => {
  //  event.stopPropagation();
  //  trace(`Email: ${emailAddress}`);
  //  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
  //    setEmailStyle({ border: "none",fontWeight: "normal" });
  //  } else {
  //    setEmailStyle({ border: "red 2px solid",fontWeight: "bold" });
  //    //EmailRef.current.focus()
  //  }
  //  trace(`Email: ${emailAddress}`);
  //  SessionInfo.Email = emailAddress;
  //}     
  //--                                                                                                                         
  chgShowPW = (event) => {
    if (this.state.showPassword === true)
      this.setState({ showPassword: false });
    else
      this.setState({ showPassword: true });
    //trace(`showPassword: ${showPassword}`);
  }
  chgStayLO = (event) => {
    if (this.state.stayLoggedOn === true) {
      this.setState({ stayLoggedOn: false });
      SessionInfo.stayLoggedOn = false;
    } else {
      this.setState({ stayLoggedOn: true });
      SessionInfo.stayLoggedOn = true;
    }
  }
  //chgPassword = (event) => {
  //  setPassword(event.target.value);
  //}
  blurPassword = (event) => {
    event.preventDefault();
    trace(`On Blur Password: ${password}`);
    SessionInfo.Password = password;
  }
  //chgConfirm = (event) => {
  //  setConfirm(event.target.value);
  //}
  blurconfirmPassword = (event) => {
    event.preventDefault();
    SessionInfo.confirmPassword = confirmPassword;
  }
  setShowPassword = (onOff) => {
    this.setState({ showPassword: onOff });
  }
  // submit Passworda for Password Change
  changePassword = async (event) => { 
    SessionInfo.Email = this.state.emailAddress;
    SessionInfo.Password = this.state.password;
    SessionInfo.confirmPassword = this.state.confirmPassword;
    trace(`Email: ${this.state.emailAddress}, Password: ${this.state.password}, Confirm Password: ${this.state.confirmPassword}`);
    //setShowMsg(false);
    //event.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.showWarning("Passwords must match");
    }
    else if (this.state.password.length < 8) {
      this.showWarning("Password must be at least 8 characters");
    }
    else {
      trace("----> Call ChangePassword");
      let m = await ChangePassword(this.state.emailAddress,this.state.password,this.state.confirmPassword,this.changeToken);
      trace(`Return from ChangePassword - m: ${m}`);
      if (m === 'ok') {
        this.showMessage(`Password Change Successful`);
        // Logon
        this.LoginInProgress = true;
        // 26May23 - DEAL WITH DIFFERENT NAMES
        trace(`----> logon email: ${SessionInfo.Email}, password: ${SessionInfo.password}`);
        this.setMesgColor("black");
        this.showMessage(`Sign In To App`);
        //let m = await LogOn(this.state.emailAddress,this.state.password);
        let m = await LogOn(this.state.emailAddress,"Checkout4#");  // Test
        this.LoginInProgress = false;
        trace(`----> Sign in - m: ${m}, UserName: ${this.state.emailAddress}, UserID: ${SessionInfo.OMUserID}`);
        if (m === 'ok') {
          this.clearMessage();
          this.setMesgColor("black");
          this.showMessage(`Signed In: '${m}'`);
          SessionInfo.UserName = this.state.emailAddress; 
          this.props.navigate(`/${SessionInfo.gotoAutoPage}`);
          this.setShowPassword(false);
        }
        else if (m) {             
          this.setError(m);
        } else if (this.state.emailAddress !== '') {
          this.setMesgColor("red");
          this.setFailCount(failCount + 1);
          this.setWarning("Unable to Sign In");
        }
      }
      else if (m === 'pa') {
        this.showWarning("Passwords must match");
      }
      else if (m)
        this.showError(m);
      else
        this.showWarning("Change Password Error");
    }
    //this.setState({ showChangePWPopup: false });
  }
  //--------- Handle Restaurant Scan --------------------------------------------------------
  scanRestaurant = async () => {
    this.setState({ ScanTitle: 'Scan The Restaurant Code' });
    this.setState({ showTableQR: true });
    //this.scanType = this.scanTypes.Table;
    this.startScan();
  }
  handleScan = (data) => {
    trace(`handleScan: ${data}`);
    if (data) {
      this.setState({ scannedString: data });
      codeReader.reset();
      this.setState({ showTableQR: false });
      this.showMessage(`Scanned: ${data}`); 
    }
  };
  handleError = (err) => {
    console.error(err);
  };
  cancelQR = () => {
    codeReader.reset();
    this.setState({ showTableQR: false });
    this.showWarn('Scan Cancelled');
  }
  startScan = () => {
    trace('ZXing code reader initialized');
    codeReader.decodeFromInputVideoDevice(undefined,'scannerVideo').then(this.handleScan).catch(this.handleError);
  };
  //-----------------------------------------------------------------------------------------

  //onItemClick = async (event) => {
  //  console.log("Event - name: " + JSON.stringify(event.item));
  //  if (event.item.text === "Scanner") {
  //    event.item.selected = true;
  //    this.currentShow = 1;
  //    this.state.bottomDisplay = 5;
  //  }
  //  else if (event.item.text === "Customer Ticker") {
  //    event.item.selected = true;
  //    this.currentShow = 1;
  //    this.state.bottomDisplay = 5;
  //  }
  //  else if (event.item.text === "Hide Header") { // 
  //    event.item.selected = true;
  //    document.documentElement.style.setProperty('--main-top', '0px');
  //    document.documentElement.style.setProperty('--main-bottom', '0px');
  //    event.item.selected = !event.item.selected;
  //    event.item.text = "Show Header";
  //  }
  //  else if (event.item.text === "Show Header") { // 
  //    event.item.selected = true;
  //    document.documentElement.style.setProperty('--main-top', '106px');
  //    document.documentElement.style.setProperty('--main-bottom', '40px');
  //    event.item.selected = !event.item.selected;
  //    event.item.text = "Hide Header";
  //  }
  //  else if (event.item.text === "Stress Test") { // 
  //    event.item.selected = true;
  //    this.currentShow = 2;
  //    this.state.bottomDisplay = 6;
  //  }
  //  this.forceUpdate();
  //}
  //-------------------------------------------------------------------
  onStartStressTest1 = async () => {
    displayMessage("Start Stress Test 1");
    if (this.state.test1TimesPerSecond > 100)
      this.state.test1TimesPerSecond = 100;
    else if (this.state.test1TimesPerSecond <= 0)
      this.state.test1TimesPerSecond = 1;
    this.state.test1WaitCount = 100 / this.state.test1TimesPerSecond;
    this.state.testStartTime = new Date();
    this.state.test1Counter = 0;
    this.state.runTest1 = true;
    trace("Stress Test 1 - counter: " + this.state.test1WaitCount);
  }
  onStopTests = async () => {
    if (this.state.runTest1 === true) {
      this.state.runTest1 = false;
      displayMessage("STOP Stress Test 1");
    }
  }
  async runTest1() {
    try {
      this.test1InProgress = true;
      trace("Run Test1");
      let Desc = "Test 1 Frequency: " + this.state.test1TimesPerSecond;
      let CD = await CallOM("TransTest1",0,0,CTYP.STR,Desc); // TransTest1 
      this.setState({ test1SendTxCount: this.state.test1SendTxCount + 1 });
      trace(`test1 data: ${JSON.stringify(CD.d)}`);
      trace(`test1 data length: ${Object.keys(CD.d).length}`);
      this.setState({ test1ReturnLength: this.state.test1ReturnLength += Object.keys(CD.d).length });
    }
    catch (ex) {
      trace("Error in runTest1: " + ex)
    }
    this.test1InProgress = false;
  }
  //-------------------------------------------------------------------
  async perfProbe() {
    try {
      let CD = await CallOM("GetPerfData",0,0,0); // PerfProbe
      //trace("PerfData: " + JSON.stringify(CD.d));
      let activeUsers = CD.d["NumberLoggedOn"];
      let transactionCount = CD.d["TxCount"];
      let totalTransactionCount = CD.d["TotalTxCount"];
      let strataCount = CD.d["StrataCount"];
      //trace("Users: " + activeUsers + "transactionCount: " + transactionCount);
      this.setState({ activeUsers });
      this.setState({ transactionCount });
      this.setState({ totalTransactionCount });
      this.setState({ strataCount });
    }
    catch (ex) {
      trace(`Error in perfProbe: ${ex}`);
      displayError("Error in Testing - Tests Cancelled");
      this.state.runTest1 = false;
    }
  }
  //-------------------------------------------------------------------
  onExpandChange = (event) => {
    event.item.expanded = !event.item.expanded;
    this.forceUpdate();
  }           
  //-------------------------------------------------------------------------------------------------------------
  render() {
    return (
      <div>
        {/*<div ref="snav" className="overlay" style={this.state.style}>
            <img src={require("./images/loading.gif")} alt="" style={this.state.style} />
          </div> */}
        {SessionInfo.loggedOn === false ?
          <div id="AppMain" className="pageMain">
            <div id="mainCntr">
              <div className="mainBox">
              </div>
            </div>
          </div >
          :
          <div id="AppMain" className="pageMain">
            <div id="mainCntr">
              {/*---------------------------------------------------------------------------------------------------------------------------------------------------------*/}
            </div>
          </div >}
        {/* Login Type Selector */}
        <Popup offset={this.popupOffset} show={this.state.showLoginPopup} popupClass={'popupLogin'} >
          <div>
            <br />
            <div>
              <Button className="loginButton" icon="unlock" onClick={this.interactLogin}>Interact Login</Button>
            </div>
            <br />
            <br />
            <div>
              <GoogleLogin
                clientId="2281835576-9i7egfp94jnmlbrcmsbd3cr7mqe4djbb.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={this.pResponseGoogle}
                onFailure={this.nResponseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>
            {SessionInfo.hasPhoneLogin == true ?
              <>
                <br />
                <br />
                <div>
                  <Button className="loginButton" icon="unlink-horizontal" onClick={this.phoneLogin}>Identify & Start</Button>
                </div>
              </> : null}
            <br />
            <br />
            <div>
              <Button className="loginButton" icon="launch" onClick={this.noLogin}>NO Login</Button>
            </div>
          </div>
        </Popup>
        {/* Change Password Popup */}
        <Popup offset={this.popupOffset} show={this.state.showChangePWPopup} popupClass={'popupChangePW'} >
          <div id="Login" className="pageLoginBorder">
            <div id="div1" className="insideFrame">
              <a id="headerLogo" href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
              <div className="loginPageInside">
                <label className="loginHeadingS">&nbsp; Interact Menu Password Change</label>
                <br />
                <br />
                <label className="loginLabel" style={{ color: this.state.mesgColor }}>{this.state.loginMessage}</label>
                <br />
                <br />
                <br />
                <div id="div2" className="editField">
                  <FloatingLabel label="Email Address (User Name) *" style={{ width: '300',color: 'darkblue' }} editorId={'emailAddress'} editorValue={this.state.emailAddress}>
                    <Input id={'emailAddress'} value={this.state.emailAddress} style={{ width: 210 }} readOnly={this.state.emReadOnly} onChange={evt => this.updateEmailAddress(evt)} />
                  </FloatingLabel>
                </div>
                <br />
                <br />
                <div id="div2" className="editField">
                  <FloatingLabel label="Password *" style={{ width: '300',color: 'darkblue' }} editorId={'password'} editorValue={this.state.password}>
                    {this.state.showPassword === false ?
                      <Input id={'password'} value={this.state.password} style={{ width: 210 }} type="password" autoComplete="false" onChange={evt => this.updatePassword(evt)} />
                      :
                      <Input id={'password'} value={this.state.password} style={{ width: 210 }} autoComplete="false" onChange={evt => this.updatePassword(evt)} />}
                  </FloatingLabel>
                </div>
                <br />
                <div id="div2" className="pwField">
                  <input type="checkbox" checked={this.state.showPassword} name="ShowPassword" onChange={this.chgShowPW} />
                  <span className="editFieldLabel">&nbsp; Show Password</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <input type="checkbox" checked={this.state.stayLoggedOn} name="StayLoggedOn" onChange={this.chgStayLO} />
                  <span className="editFieldLabel">&nbsp; Stay Logged On</span>
                </div>
                Your Password must be 8 or more characters    With at least one Capital, one Number and one Symbol
                <br />
                <div id="div2" className="editField">
                  <FloatingLabel label="Confirm Password" style={{ width: '300',color: 'darkblue' }} editorId={'confirmPassword'} editorValue={this.state.confirmPassword}>
                    {this.state.showPassword === false ?
                      <Input id={'confirmPassword'} value={this.state.confirmPassword} style={{ width: 210 }} type="password" autoComplete="false" onChange={evt => this.updateConfirm(evt)} />
                      :
                      <Input id={'confirmPassword'} value={this.state.confirmPassword} style={{ width: 210 }} autoComplete="false" onChange={evt => this.updateConfirm(evt)} />}
                  </FloatingLabel>
                </div>
                <br />
                <br />
                <br />
                <Button className="submitButton" onClick={this.changePassword}>CHANGE PASSWORD</Button>
              </div>
            </div>
          </div>
        </Popup>
        {/* Message Notify Popup */}
        <Popup offset={SessionInfo.popupNotifyOffset} show={this.state.showNotify} popupClass={'popup-content'} >
          <div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }} onClick={(e) => { this.acknowledgeMessage(); }} />
          {SessionInfo.acknowledge ?
            <div className="bottomButton">
              <Button icon="tell-a-friend" className="mediumButton" onClick={this.acknowledgeMessage}>Acknowledge</Button>
            </div> : null}
        </Popup>
        {/* About InteractMenu */}
        <Popup offset={this.popupIMOffset} show={this.state.showIMPopup} popupClass={'popupIM'} >
          <div>
            <div className="headerIMTop">
              <a href="https://omnovos.com" target="_blank" rel="noopener noreferrer"> <img src={require("./images/omNovos.png.webp")} alt="" className="omImage" /></a>
            </div>
            <div className="headerIMMiddleTop">
              <span>You have not selected a restaurant.</span>
              <br />
              <br />
              <span>Locate the QR code for the restaurant.</span>
              <br />
              <span>Press the Scan Restaurant button.</span>
              <br />
            {/*  <span>Note that depending on the Restaurant, you may need to sign in.</span>*/}
            {/*  <a href="https://omnovos.com" target="_blank" rel="noopener noreferrer"> <img src={require("./images/omNovos.png.webp")} alt="" className="omImage" /></a>*/}
            </div>
            <div className="headerIMMiddle">
              <div className="headerIMButton">
                <Button className="medFxdButton" onClick={this.scanRestaurant}>Scan Restaurant</Button>
              </div>
              {/*  <a href="https://omnovos.com" target="_blank" rel="noopener noreferrer"> <img src={require("./images/omNovos.png.webp")} alt="" className="omImage" /></a>*/}
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </Popup>
        <Popup offset={this.showScannerOffset} show={this.state.showTableQR} popupClass={'getQRPopup'} >
          <div className="getQR">
            <div className="barcodeScannerBox">
              <video id="scannerVideo" name="videoScan" width="300px" height="300px" margin='1' padding='1' style={{ border: '1px solid gray',}} />
            </div>
            <div className="scanTitleDiv">
              <span style={{ fontSize: 19,color: 'blue',fontWeight: "bolder" }} >{this.state.ScanTitle}</span>
            </div>
            <Button className="mediumButton" style={{ left: '100px',right: '100px',top: '20px' }} onClick={this.cancelQR}>Cancel QR</Button>
          </div>
        </Popup>
      </div >
    );
  }
}
export default withRouter(AppMain);