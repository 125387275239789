//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ManagerPage - Interact Menu Manager Page
//                      Version 1.00 - April 4, 2023
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React,{ Component } from 'react';
import { SessionInfo } from './App';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import { RadialGauge } from '@progress/kendo-react-gauges';
import { TreeView } from '@progress/kendo-react-treeview';
import { ENAppCnfg } from './CommonInfo.js';
import { GetMonth,GetDay } from './CommonFormat.js';
import { BrowserMultiFormatReader } from '@zxing/library'; // Barcode Scanner
import { withRouter } from './withRouter';
import { CallOM,CallOMT,OMTrace,trace,GetTableSearchRaw,displayMessage,LogOffResetMsg,CTYP,TYP,NOTIFY,SetNotify } from './CommonCode.js';

import { ReactComponent as MenuBar } from "./images/bars.svg";

const codeReader = new BrowserMultiFormatReader();
// Gauge - Current Guests
let range1 = [0,50,100,150,200];
let range2 = [0,2000,4000,6000,8000];
let range100 = [0,250,500,750,1000];
const dateFormat = (value) => new Intl.DateTimeFormat('en-CA',{ dateStyle: 'medium',timeStyle: 'short' }).format(Date.parse(value));

class ManagerPage extends React.Component {
  constructor(props) {
    super(props);
    if (!SessionInfo.session || SessionInfo.session === '')
      return;
  }
  menuAnchor = React.createRef();
  state = {
    QuestionID: 0,
    Name: '',
    EmailAddress: '',
    QuestionTypeID: '',
    MessageDetail: '',
    showMenu: false,
    StoreName: '',
    TableName: '',
    SeatCount: 0,
    SeatName: '',
    ItemCount: 0,
    NotifyMessage: '',
    activateMessageBox: false,
    ScanTitle: '',
    activeCustomers: 0,
    totalSales: 0,

    currentTime: '',
    currentDate: '',

    ErrorMessage: '',
    ErrorFlag: 0,
    CustomerName: '',
    CustomOrderID: 0,
    TotalPrice: 0,
    TotalTax: 0,
    TipAmount: 0,
    TipAmountS: '',
    AmountOwing: 0,
    TotalBill: 0,
    AmountPaid: 0,
    AmountPaidS: '',
    CreatedOn: new Date(),


    HoursOfOperation: [],
    HoursLth: 0,
    HoursForToday: '',

    scannedString: '',
    showTableQR: false,
    showAcceptCash: false,
  };
  currentType = '';
  refreshCount = 6;
  compUpdate = true;
  HoursForToday = '';
  custCount = 0;
  messageColor = 'blue';
  messageCounter = 0;
  scanType = 0;
  showScannerOffset = { left: 50,top: 50 };
  popupCashPayOffset = { left: 50,top: 150 };

  moneyFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(value);

  scanTypes = {
    Table: 1,
    Order: 2,
    OrderItem: 3,
    CustomerCredit: 4,
    CustomerVerify: 5,
    CustomerCash: 6,
  }

  async componentDidMount() {
    trace(`==> ManagerPage Did Mount`);
    if (!SessionInfo.session || SessionInfo.session === '')
      return;
    this.setState({ currentTime: this.getTime() });
    this.setState({ Name: SessionInfo.FullName });
    this.setState({ EmailAddress: SessionInfo.logonEmailAddress });
    SessionInfo.currentPage = "ManagerPage";
    this.setState({ QuestionID: 0 });
    this.setState({ StoreName: SessionInfo.StoreName });
    this.setState({ TableName: SessionInfo.CurrentTableName });
    this.setState({ SeatName: SessionInfo.SeatName });
    this.setState({ SeatCount: SessionInfo.SeatCount });
    this.setState({ ItemCount: SessionInfo.ItemCount });
    this.setState({ NotifyMessage: '' });
    await this.GetHoursOfOperation();
    await this.GetStoreHoursOfOperationForToday();
    this.runMonitorProcess = true;
    this.monitorProcess();
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    //let value = this.context; 
    this.runMonitorProcess = false;
    if (this.timeout)
      clearTimeout(this.timeout);
  }
  shouldComponentUpdate() {
    //trace("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  getTime() { // Get CLOCK time
    const curTime = new Date();
    if (curTime) {
      let currentDate = GetDay(curTime.getDay()) + ' - ' + GetMonth(curTime.getMonth()) + ' ' + curTime.getDate(); // + ', ' + curTime.getFullYear();
      this.setState({ currentDate });
      const currentTime =
      {
        hours: curTime.getHours(),
        minutes: curTime.getMinutes(),
        //seconds: curTime.getSeconds(),
        //ampm: curTime.getHours() >= 12 ? "pm" : "am"
      };
      return currentTime;
    }
    else
      return 'Time';
  }
  runMonitorProcess = false;
  setTimer() {
    if (SessionInfo.loggedOn === true && this.runMonitorProcess === true)
      this.timeout = setTimeout(this.monitorProcess.bind(this),200); // 200 microseconds
    //this.interval = setInterval(this.monitorProcess.bind(this), 200); // 200 microseconds
    // clearInterval(this.interval);
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess = async (force) => { // Note - Monitor only runs if logged on
    if (force)
      this.runMonitorProcess = true;
    //trace(`monitor logoff: ${SessionInfo.forceLogoff}, On: ${SessionInfo.loggedOn}`); 
    if (SessionInfo.forceLogoff === true) {
      SessionInfo.forceLogoff = false;
      this.props.navigate("/Login");
    }
    if (this.runMonitorProcess === true && SessionInfo.loggedOn === true) {
      this.setTimer();
      if (this.refreshCount++ > 4) { // Once a second
        this.setState({ currentTime: this.getTime() });
        this.refreshCount = 0;
      }
      if (this.custCount++ > 10) {
        this.custCount = 0;
        this.setState({ activeCustomers: this.state.activeCustomers + 1 });
        this.setState({ totalSales: this.state.totalSales + 10 });
      }
      //if (this.messageCounter > 0) {
      //  //trace(`messageCnt: ${this.messageCounter}`);
      //  this.messageCounter--;
      //  if (this.messageCounter <= 0)
      //    this.messageClear();
      //}
      if (1 === 1 || document.hasFocus() === true) { // Execute monitor process
        //---- Ping and Notification Processing -------------
        if (SessionInfo.setShowNotify > 0 && SessionInfo.notifyMessage.length > 0) {
          this.setState({ notifyMessage: SessionInfo.notifyMessage });
          SessionInfo.notifyTimer = SessionInfo.notifyTime;
          this.setState({ showNotify: true }); // Show the Message    
          if (SessionInfo.notifyRing && (SessionInfo.SoundOn === true || SessionInfo.SoundOverride === true)) {
            //trace(`Play Notify Sound: ${SessionInfo.notifyRing}`);
            try {
              await SessionInfo.notifyRing.play();
            }
            catch (Ex) {
              trace(`Ring Ex: ${Ex}`);
            }
            SessionInfo.notifyRing = undefined;
            SessionInfo.SoundOverride = false;
          }
        }
        SessionInfo.setShowNotify = 0;
        if (SessionInfo.notifyTimer > 0) {
          if (--SessionInfo.notifyTimer <= 0)
            this.setState({ showNotify: false });
        } else if (SessionInfo.showNotify === true)
          this.setState({ showNotify: false }); // Hide the Notify Message
        if (SessionInfo.pingCounter++ > 50) {
          SessionInfo.pingCounter = 0;
          let CD = await CallOM("PingApp",SessionInfo.StoreLocationID,SessionInfo.CustomOrderID,CTYP.STR,"");
          if (CD && CD.d && CD.d.rows) {
            //trace(`Return from PingApp - CD: ${JSON.stringify(CD)}`);
            let rows = CD.d.rows;
            for (let ix = 0; ix < rows.length; ix++) {
              let row = rows[ix];
              SetNotify(row["NotificationMessage"],row["NotificationTypeID"]);
              SessionInfo.AppNotificationID = row["AppNotificationID"];
            }
          }
        }
      }
      //---- End of Notification Processing -------------
    }
  }
  acknowledgeMessage = async () => {
    let CD = await CallOM("AcknowledgeNotification",0,SessionInfo.AppNotificationID);
    this.setState({ showNotify: false });
    SessionInfo.notifyTimer = 50;
    SessionInfo.acknowledge = false;
    this.forceUpdate();
  }
  openMenu = (event) => {
    event.stopPropagation();
    this.setState({ showMenu: true });
    trace(`open Menu - showMenu: ${this.state.showMenu}`);
  }
  onMenuItemClick = async (event) => {
    trace(`menu event Item: ${event.item.text}`);
    if (SessionInfo.currentItem !== undefined) {
      //trace('Current Item: ' + SessionInfo.currentItem.text);
      SessionInfo.currentItem.selected = false;
    }
    SessionInfo.currentItem = event.item;
    trace('2 Current Item: ' + SessionInfo.currentItem.text);
    event.item.selected = true;
    event.item.expanded = true;
    //trace(`Event - name: ${JSON.stringify(event.item)}`);
    SessionInfo.MenuSelect = event.item;
    this.keepMenu = false;
    this.setState({ showMenu: false });
    if (SessionInfo.menuAction !== undefined) {
      await SessionInfo.menuAction(); // onMenuItemClick in AppMain.js
    }
    this.forceUpdate();
  }
  closePopups(src) {
    this.setState({ showMenu: false });
  }
  //
  //CDATA CD = await AccessOM.CallOM("GetRootPromotionIDs", 0, App.LPM.StoreLocationID, 0);
  //var JRws = (JArray)CD.d;

  //// Update Hours of Operations  
  //CD = await AccessOM.CallOM("GetStoreHoursOfOperationForToday", 0, App.LPM.StoreLocationID, 0);
  //App.LPM.StoreHours = (string)CD.d;
  GetStoreHoursOfOperationForToday = async () => {
    let HoursForToday;
    try {
      let CD = await CallOM("GetStoreHoursOfOperationForToday",0,SessionInfo.StoreLocationID,0);
      HoursForToday = CD.d.toString();
    }
    catch (Ex) {
      await OMTrace(2,`Failure in GetStoreHoursOfOperationForToday - Error: ${Ex}`);
    }
    await this.setState({ HoursForToday: HoursForToday });
    trace(`ManagerPage - HoursForToday: ${HoursForToday}`);
  }

  GetHoursOfOperation = async () => {
    let DaysHours = [];
    try {
      let CD = await CallOM("GetStoreHoursOfOperation",0,SessionInfo.StoreLocationID,0);
      trace(`DayHours: ${JSON.stringify(CD.d)}`);
      let str = CD.d.toString();
      trace(`str: ${str}`);
      DaysHours = str.split(',');
      trace(`DayHours: ${JSON.stringify(DaysHours)}`);
    }
    catch (Ex) {
      await OMTrace(2,`Failure in GetHoursOfOperation - Error: ${Ex}`);
    }
    this.setState({ HoursOfOperation: DaysHours });
    this.setState({ HoursLth: DaysHours.length / 2 });
  }
  //-------------------------------------------------------------------------------------------------------------------------------------
  // Manager Functions
  //-------------------------------------------------------------------------------------------------------------------------------------
  clearTable = async () => {
    this.setState({ ScanTitle: 'Scan The Table QR Code' });
    this.setState({ showTableQR: true });
    this.scanType = this.scanTypes.Table;
    this.startScan();
  }
  voidOrder = async () => {
    trace(`void Order`);
    this.setState({ ScanTitle: 'Scan The Order QR Code' });
    this.setState({ showTableQR: true });
    this.scanType = this.scanTypes.Order;
    this.startScan();
  }
  voidItem = async () => {
    trace(`void Item`);
    this.setState({ ScanTitle: 'Scan The Order Item QR Code' });
    this.setState({ showTableQR: true });
    this.scanType = this.scanTypes.OrderItem;
    this.startScan();
  }
  addCredit = async () => {
    trace(`Add Credit`);
    this.setState({ ScanTitle: 'Scan The Customer QR Code' });
    this.setState({ showTableQR: true });
    this.scanType = this.scanTypes.CustomerCredit;
    this.startScan();
  }
  verifyPayment = async () => {
    trace(`verify Payment`);
    this.setState({ ScanTitle: 'Scan The Order QR Code' });
    this.setState({ showTableQR: true });
    this.scanType = this.scanTypes.CustomerVerify;
    this.startScan();
  }
  cashPayment = async () => {
    trace(`cashPayment`);
    this.setState({ ScanTitle: 'Scan The Cash Pay QR Code' });
    this.setState({ showTableQR: true });
    this.scanType = this.scanTypes.CustomerCash;
    this.startScan();
  }
  handleScan = (data) => {
    trace(`handleScan: ${data}`);
    if (data) {
      this.setState({ scannedString: data });
      codeReader.reset();
      this.setState({ showTableQR: false });
      this.showMessage(`Scanned: ${data}`);
      switch (this.scanType) {
        case this.scanTypes.Table:
          this.TableClean(data);
          break;
        case this.scanTypes.Order:
          this.VoidOrder(data);
          break;
        case this.scanTypes.OrderItem:
          this.VoidOrderItem(data);
          break;
        case this.scanTypes.CustomerCredit:
          this.GiveCustomerCredit(data);
          break;
        case this.scanTypes.CustomerVerify:
          this.VerifyPayment(data);
          break;
        case this.scanTypes.CustomerCash:
          this.CustomerCash(data);
          break;
        default:
          trace(`Unknown Scan Type`);
          break;
      }
    }
  };
  handleError = (err) => {
    console.error(err);
  };
  cancelQR = () => {
    codeReader.reset();
    this.setState({ showTableQR: false });
    this.showWarn('Scan Cancelled');
  }
  startScan = () => {
    trace('ZXing code reader initialized');
    codeReader.decodeFromInputVideoDevice(undefined,'scannerVideo').then(this.handleScan).catch(this.handleError);
  };
  //-------------------------------------------------------------------------------------------------------------------------------------
  // Management Functions
  //-------------------------------------------------------------------------------------------------------------------------------------
  TableClean = async (data) => { // CleanTable
    let keyInfo = data.toString();
    trace(`TableClean - date: ${keyInfo}`);
    try {
      let CD = await CallOM("TableClean",SessionInfo.StoreLocationID,0,CTYP.STR,keyInfo);
      trace(`On Return - CD: ${JSON.stringify(CD)}`);
      if (CD && CD.x.o < 9500) {
        if (CD.d) {
          this.showMessage(CD.d);
          //let HelpPages = CD.d.rows.map(dataItem => Object.assign({ isFavorite: false,selected: false },dataItem));
        }
      }
      else
        this.showError(`Error on Table Clean`);
    } catch (error) {
      await OMTrace(2,`Failure TableClean - Error: ${error}`);
    }
  }
  VoidOrder = async (data) => {
    let keyInfo = data.toString();
    trace(`VoidOrder - data: ${keyInfo}`);
    try {
      let CD = await CallOM("VoidOrder",SessionInfo.StoreLocationID,0,CTYP.STR,keyInfo);
      trace(`On Return - CD: ${JSON.stringify(CD)}`);
      if (CD && CD.x.o < 9500) {
        if (CD.d) {
          this.showMessage(CD.d);
        }
      }
      else
        this.showError(`Error on VoidOrder`);
    } catch (error) {
      await OMTrace(2,`Failure VoidOrder - Error: ${error}`);
    }
  }
  VoidOrderItem = async (data) => {
    let keyInfo = data.toString();
    trace(`VoidOrderItem - data: ${keyInfo}`);
    try {
      let CD = await CallOM("VoidOrderItem",SessionInfo.StoreLocationID,0,CTYP.STR,keyInfo);
      trace(`On Return - CD: ${JSON.stringify(CD)}`);
      if (CD && CD.x.o < 9500) {
        if (CD.d) {
          this.showMessage(CD.d);
        }
      }
      else
        this.showError(`Error on VoidOrderItem`);
    } catch (error) {
      await OMTrace(2,`Failure VoidOrderItem - Error: ${error}`);
    }
  }
  GiveCustomerCredit = async (data) => {
    let keyInfo = data.toString();
    trace(`GiveCustomerCredit - data: ${keyInfo}`);
    try {
      let CD = await CallOM("GiveCustomerCredit",SessionInfo.StoreLocationID,0,CTYP.STR,keyInfo);
      trace(`On Return - CD: ${JSON.stringify(CD)}`);
      if (CD && CD.x.o < 9500) {
        if (CD.d) {
          this.showMessage(CD.d);
        }
      }
      else
        this.showError(`Error on GiveCustomerCredit`);
    } catch (error) {
      await OMTrace(2,`Failure GiveCustomerCredit - Error: ${error}`);
    }
  }
  VerifyPayment = async (data) => {
    let keyInfo = data.toString();
    trace(`VerifyPayment - data: ${keyInfo}`);
    try {
      let CD = await CallOM("VerifyPayment",SessionInfo.StoreLocationID,0,CTYP.STR,keyInfo);
      trace(`On Return - CD: ${JSON.stringify(CD)}`);
      if (CD && CD.x.o < 9500) {
        if (CD.d) {
          this.showMessage(CD.d);
        }
      }
      else
        this.showError(`Error on VerifyPayment`);
    } catch (error) {
      await OMTrace(2,`Failure VerifyPayment - Error: ${error}`);
    }
  }
  CustomerCash = async (data) => {
    let keyInfo = data.toString();
    trace(`CustomerCash - data: ${keyInfo}`);
    try {
      let CD = await CallOM("CustomerCash",SessionInfo.StoreLocationID,0,CTYP.STR,keyInfo);
      trace(`On Return - CD: ${JSON.stringify(CD)}`);
      if (CD && CD.x.o < 9500) {
        if (CD.d) {
          let dict = CD.d;
          this.setState({ ErrorMessage: dict.ErrorMessage });
          this.setState({ ErrorFlag: dict.ErrorFlag });
          this.setState({ CustomOrderID: dict.CustomOrderID });
          this.setState({ CreatedOn: dict.CreatedOn });
          this.setState({ TotalPrice: dict.TotalPrice });
          this.setState({ TotalTax: dict.TotalTax });
          this.setState({ AmountOwing: dict.AmountOwing });
          this.setState({ CustomerName: dict.CustomerName });
          this.setState({ TipAmount: dict.TipAmount });
          this.setState({ TipAmountS: this.moneyFormat(dict.TipAmount) });
          this.setState({ TotalBill: dict.TotalBill });
          this.setState({ AmountPaid: dict.AmountPaid });
          this.setState({ ChangeOwing: dict.ChangeOwing });
          this.setState({ showAcceptCash: true }); // Display Popup
          //this.showMessage(CD.d);
        }
      }
      else
        this.showError(`Error on CustomerCash`);
    } catch (error) {
      await OMTrace(2,`Failure CustomerCash - Error: ${error}`);
    }
  }
  payCashBill = async () => {
    let keyInfo = this.state.CustomOrderID.toString() + '|' + this.state.TipAmount.toString() + '|' + this.state.AmountPaid.toString();
    trace(`payCashBill - data: ${keyInfo}`);
    try {
      let CD = await CallOM("PayCustomerCash",SessionInfo.StoreLocationID,0,CTYP.STR,keyInfo);
      trace(`On Return - CD: ${JSON.stringify(CD)}`);
      if (CD && CD.x.o < 9500) {
        if (CD.d) {
          let dict = CD.d;
          //this.setState({ AmountPaid: dict.AmountPaid });
          //let changeOwing = parseFloat(dict.ChangeOwing) 
          this.setState({ ErrorMessage: dict.ErrorMessage });
          this.setState({ ErrorFlag: dict.ErrorFlag });
          this.setState({ TotalBill: dict.TotalBill });
          this.setState({ ChangeOwing: dict.ChangeOwing });
          this.setState({ showAcceptCash: false });
        }
      }
      else
        this.showError(`Error on payCashBill - o: ${CD.x.o}`);
    } catch (error) {
      await OMTrace(2,`Failure payCashBill - Error: ${error}`);
    }
  }
  cancelPay = async () => {
    this.setState({ showAcceptCash: false });
  }
  //-------------------------------------------------------------------------------------------------------------------------------------
  showMessage = (msg) => {
    this.messageColor = 'blue';
    this.messageCounter = 25;
    this.setState({ NotifyMessage: msg });
    this.setState({ activateMessageBox: true });
  }
  messageClear = (msg) => {
    this.messageColor = 'blue';
    this.messageCounter = 0;
    this.setState({ NotifyMessage: '' });
    this.setState({ activateMessageBox: false });
  }
  showWarn = (msg) => {
    this.messageColor = 'orange';
    this.messageCounter = 30;
    this.setState({ NotifyMessage: msg });
    this.setState({ activateMessageBox: true });
  }
  showError = (msg) => {
    this.messageColor = 'red';
    this.messageCounter = 35;
    this.setState({ NotifyMessage: msg });
    this.setState({ activateMessageBox: true });
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }
  async blurMoneyFldVal(event) {
    //trace(`money target: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name + 'S']: this.moneyFormat(this.state[event.target.name]) });
    //trace(`money target: ${this.state[event.target.name + 'S']}`);
    if (event.target.name == "TipAmount") {
      let tipAmount = this.state[event.target.name];
      let AmountOwing = this.state.AmountOwing;
      AmountOwing += tipAmount;
      this.setState({ TotalBill: AmountOwing });
    }
    else if (event.target.name == "AmountPaid") {
      let AmountPaid = this.state.AmountPaid;
      let TotalBill = this.state.TotalBill;
      let ChangeOwing = AmountPaid - TotalBill;
      if (ChangeOwing < 0.0) {
        this.setState({ ErrorMessage: "Insufficient Payment" });
        this.setState({ ErrorFlag: 2 });
        this.setState({ ChangeOwing: 0.0 });
      }
      else
        this.setState({ ErrorMessage: "" });
      this.setState({ ErrorFlag: 0 });
      this.setState({ ChangeOwing });
    }
  }
  //    
  async focusMoneyFldVal(event) {
    //trace(`money target: ${this.state[event.target.name]}`);
    if (this.state[event.target.name] === 0)
      this.setState({ [event.target.name + 'S']: '' });
    else
      this.setState({ [event.target.name + 'S']: this.state[event.target.name] });
    //trace(`money target: ${this.state[event.target.name + 'S']}`);
  }
  async chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,''); // Matches any single single character not in the bracket [] and replaces with ''  - https://www.infobyip.com/regularexpressioncalculator.php
    //trace(`money newval: ${newVal}, target: ${this.state[event.target.name]}, name: ${event.target.name}`);
    //this.setState({ [event.target.name + 'S']: this.moneyFormat(newVal) });    
    let tstVal = +newVal;
    if (Number.isNaN(tstVal))
      newVal = event.target.value.replace(/-/g,''); // Remove the - sign
    this.setState({ [event.target.name + 'S']: newVal });
    await this.setState({ [event.target.name]: newVal / 1.0 });
    //trace(`updated target: ${this.state[event.target.name]}, str: ${this.state[event.target.name + 'S']}`);
    this.setState({ stateHasChanged: true });
  }
  async chgMoneyFldValD(event) {
    this.setState({ TipAmountOverPS: '' });
    this.setState({ TipAmountOverP: 0.0 });
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,''); // Matches any single single character not in the bracket [] and replaces with ''  - https://www.infobyip.com/regularexpressioncalculator.php
    //trace(`money newval: ${newVal}, target: ${this.state[event.target.name]}, name: ${event.target.name}`);
    //this.setState({ [event.target.name + 'S']: this.moneyFormat(newVal) });    
    let tstVal = +newVal;
    if (Number.isNaN(tstVal))
      newVal = event.target.value.replace(/-/g,''); // Remove the - sign
    this.setState({ TipAmountOverDS: newVal });
    await this.setState({ TipAmountOverD: newVal / 1.0 });
    //trace(`updated target: ${this.state[event.target.name]}, str: ${this.state[event.target.name + 'S']}`);
    this.setState({ stateHasChanged: true });
  }
  async blurTipAmountD(event) {
    trace(`blur Tip Amount: ${this.state.TipAmountOverD}`);
    if (this.state.TipAmountOverD > 0.0) {
      this.setState({ TipAmountOverDS: this.moneyFormat(this.state.TipAmountOverD) });
      this.setState({ TipAmount: this.state.TipAmountOverD });
      let TipPercent = this.state.TipAmountOverD / this.state.AmountOwing;
      trace(`TipPercent: ${TipPercent}`);
      this.setState({ TipAmountOverPS: this.percentFormat(TipPercent) });
      let AmountOwingWithTip = this.state.AmountOwing + this.state.TipAmountOverD;
      this.setState({ AmountOwingWithTip });
      this.setState({ ActualPaymentAmount: AmountOwingWithTip.toFixed(2) });
      this.setState({ ActualPaymentAmountS: this.moneyFormat(AmountOwingWithTip.toFixed(2)) });
      //trace(`money target: ${this.state[event.target.name + 'S']}`); 
    } else if (this.state.TipAmountOverP > 0.0) {
      this.blurTipAmountP(event);
    }
  }
  updateType(evt) {
    trace("Type: " + evt.target.value);
    this.setState({ QuestionTypeID: evt.target.value });
  }
  render() {
    const { hours,minutes } = this.state.currentTime;
    return (
      <div id="ManagerPage" className="pageMainFx">
        <div id="hdr" className="headerMgr" onClick={(e) => this.closePopups(0)}>
          <div className="headerLogoLeft">
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href={SessionInfo.RestaurantURL} target="_blank" rel="noopener noreferrer" />
          </div>
          <div className="headerCenter">
            <div className="headerCenterMenu">
              <MenuBar alt="" fill={SessionInfo.headingTextColor} id="MenuBtn" title={`View Menu`} className="functionImageL" onClick={(e) => this.openMenu(e)} ref={this.menuAnchor} />
            </div>
            <div className="headerCenterTop">
              &nbsp; &nbsp; &nbsp;&nbsp;
              <span className='landingHeadText' >
                {
                  hours === 0 ? 12 : (hours > 12) ? hours - 12 : hours
                }:{
                  minutes > 9 ? minutes : `0${minutes}`
                } {/*:{seconds > 9 ? seconds : `0${seconds}`} {ampm}*/}&nbsp;&nbsp;
              </span>
              <span className='landingHeadText'>
                {this.state.currentDate}
              </span>
            </div>

            <div className="headerCenterMidH">
              <span className='landingHeadText' >&nbsp;&nbsp; {SessionInfo.PersonInfo.FirstName} {SessionInfo.PersonInfo.LastName}</span>
              <span className='landingHeadTextSmall' >&nbsp; &nbsp; &nbsp;V: {SessionInfo.appVersion}-{SessionInfo.Platform}&nbsp;&nbsp;</span>
            </div>
            {this.state.activateMessageBox ?
              <div className="managerMessage">
                <span style={{ fontSize: 15,color: this.messageColor,fontWeight: "normal" }} >{this.state.NotifyMessage}</span>
              </div> : null}
          </div>
          <div className="managerInfo">
            <div>
              <div className="HoursOfOp">
                <span className="HoursToday" >{this.state.HoursForToday}</span>
              </div>
            </div>
            {this.state.HoursLth > 0 ?
              <div className="HoursOfOp">
                <span className="HoursOfOpDay" >{this.state.HoursOfOperation[0]}</span> : <span className="HoursOfOpTime" >{this.state.HoursOfOperation[this.state.HoursLth]}</span>
              </div>
              : null}
            {this.state.HoursLth > 1 ?
              <div className="HoursOfOp">
                <span className="HoursOfOpDay" >{this.state.HoursOfOperation[1]}</span> : <span className="HoursOfOpTime" >{this.state.HoursOfOperation[this.state.HoursLth + 1]}</span>
              </div>
              : null}
            {this.state.HoursLth > 2 ?
              <div className="HoursOfOp">
                <span className="HoursOfOpDay" >{this.state.HoursOfOperation[2]}</span> : <span className="HoursOfOpTime" >{this.state.HoursOfOperation[this.state.HoursLth + 2]}</span>
              </div>
              : null}
            {this.state.HoursLth > 3 ?
              <div className="HoursOfOp">
                <span className="HoursOfOpDay" >{this.state.HoursOfOperation[3]}</span> : <span className="HoursOfOpTime" >{this.state.HoursOfOperation[this.state.HoursLth + 3]}</span>
              </div>
              : null}
          </div>
          <div className="managerDashboard">
            <div className="editTabLeft">
              <div id="div1" className="insideFrame">
                <h4>Current Guests</h4>
                <RadialGauge
                  pointer={{ value: this.state.activeCustomers }}
                  transitions={false}
                  colors
                  scale={{
                    labels: { format: 'n',color: '#008000',visible: true },
                    majorTicks: { visible: true,color: '#008000' },
                    minorTicks: { visible: true,color: '#008000' },
                    rangeSize: 5,
                    rangeLineCap: 'round',
                    startAngle: 0,
                    endAngle: 270,
                    min: 0,
                    max: range1[4],
                    reverse: false,
                    ranges: [{ to: range1[1],color: '#0058e9' },{ from: range1[1],to: range1[2],color: '#37b400' },{ from: range1[2],to: range1[3],color: '#ffc000' },{ from: range1[3],color: '#f31700' }]
                  }} />
              </div>
            </div>
            <div className="editTabRight">
              <div id="div1" className="insideFrame">
                {this.state.totalSales < 1000 ?
                  <div>
                    <h4>Total Sales for Today</h4>
                    <RadialGauge
                      pointer={{ value: this.state.totalSales }}
                      transitions={false}
                      scale={{
                        labels: { format: 'c',color: 'blue',visible: true },
                        majorTicks: { visible: true,color: '#002aff' },
                        minorTicks: { visible: true,color: '#00ff00' },
                        rangeSize: 5,
                        rangeLineCap: 'round',
                        startAngle: -45,
                        endAngle: 225,
                        min: 0,
                        max: range100[4],
                        reverse: false,
                        ranges: [{ to: range100[1],color: '#c2f0c2' },{ from: range100[1],to: range100[2],color: '#86f086' },{ from: range100[2],to: range100[3],color: '#0ff5a0' },{ from: range100[3],color: '#24ed24' }]
                      }} />
                  </div>
                  :
                  <div>
                    <h4>Total Sales for Today</h4>
                    <RadialGauge
                      pointer={{ value: this.state.totalSales }}
                      transitions={false}
                      scale={{
                        labels: { format: 'c',color: 'blue',visible: true },
                        majorTicks: { visible: true,color: '#002aff' },
                        minorTicks: { visible: true,color: '#00ff00' },
                        rangeSize: 5,
                        rangeLineCap: 'round',
                        startAngle: -45,
                        endAngle: 225,
                        min: 0,
                        max: range2[4],
                        reverse: false,
                        ranges: [{ to: range2[1],color: '#c2f0c2' },{ from: range2[1],to: range2[2],color: '#86f086' },{ from: range2[2],to: range2[3],color: '#0ff5a0' },{ from: range2[3],color: '#24ed24' }]
                      }} />
                  </div>}
              </div>
            </div>
          </div>
          <div className="managerFunctionsA">
            <div className="mangerButton">
              <Button className="medFxdButton" onClick={this.voidOrder}>Void Order</Button>
            </div>
            <div className="mangerButton">
              <Button className="medFxdButton" onClick={this.voidItem}>Void Item</Button>
            </div>
            <div className="mangerButton">
              <Button className="medFxdButton" onClick={this.addCredit}>Add Credit</Button>
            </div>
            <div className="mangerButton">
              <Button className="medFxdButton" onClick={this.verifyPayment}>Verify Payment</Button>
            </div>
            <div className="mangerButton">
              <Button className="medFxdButton" onClick={this.cashPayment}>Cash Payment</Button>
            </div>
          </div>
          <div className="managerFunctionsB">
            <div className="mangerButton">
              <Button className="medFxdButton" onClick={this.clearTable}>Table Cleaned</Button>
            </div>
          </div>
        </div>
        {/* Message Notify Popup */}
        <Popup offset={SessionInfo.popupNotifyOffset} show={this.state.showNotify} popupClass={'popup-content'} >
          <div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }} onClick={(e) => { this.acknowledgeMessage(); }} />
          {SessionInfo.acknowledge ?
            <div className="bottomButton">
              <Button icon="tell-a-friend" className="mediumButton" onClick={this.acknowledgeMessage}>Acknowledge</Button>
            </div> : null}
        </Popup>
        {/* Menu Popup */}
        <Popup anchor={this.menuAnchor.current} show={this.state.showMenu} popupClass={'popupMenu'} >
          <div className="mainMenu">
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
            <div className="menuTree">
              {SessionInfo.AppCfg.HasScanAndPay === false ?
                <br /> : null}
              {/*{SessionInfo.defaultLogon === true ?*/}
              {/*  <br /> : null}*/}
              <TreeView
                data={SessionInfo.MenuControlNQR}
                size='large' draggable='false' selection='single'
                onItemClick={this.onMenuItemClick}
                className='menuTreeSub'
                item={props => [<span key={props.item.key} className={props.item.className}>{props.item.icon}{props.item.space}{props.item.text}</span>]}
              />
            </div>
          </div>
        </Popup>
        {/* Table QR Code Popup */}
        <Popup offset={this.showScannerOffset} show={this.state.showTableQR} popupClass={'getQRPopup'} >
          <div className="getQR">
            <div className="barcodeScannerBox">
              <video id="scannerVideo" name="videoScan" width="300px" height="300px" margin='1' padding='1' style={{ border: '1px solid gray',}} />
            </div>
            <div className="scanTitleDiv">
              <span style={{ fontSize: 19,color: 'blue',fontWeight: "bolder" }} >{this.state.ScanTitle}</span>
            </div>
            <Button className="mediumButton" style={{ left: '100px',right: '100px',top: '20px' }} onClick={this.cancelQR}>Cancel QR</Button>
          </div>
        </Popup>
        <Popup offset={this.popupCashPayOffset} show={this.state.showAcceptCash} popupClass={'popupAccountDetail'} >
          {/*<div className="editTab">*/}
          <div className="editTabLeftNarrowFixed">
            <div id="div1" className="insideFrame">
              <h2>Pay Bill With Cash</h2>
              <div id="div2" className="editField">
                {this.state.ErrorFlag === 0 ?
                  <span style={{ fontSize: 19,color: 'blue',fontWeight: "bolder" }} >{this.state.ErrorMessage}</span> :
                  <span style={{ fontSize: 19,color: 'red',fontWeight: "bolder" }} >{this.state.ErrorMessage}</span>}
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabel">Customer Name</span>
                <input value={this.state.CustomerName} name="CustomerName" readOnly={true} className="editInput" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabel">Order Number</span>
                <input value={this.state.CustomOrderID} name="CustomOrderID" readOnly={true} className="editNumShortInput" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabel">Check Date Time</span>
                <DateTimePicker value={new Date(this.state.CreatedOn)} name="CreatedOn" className="editInput" readOnly={true} />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabel">Current Amount</span>
                <input value={this.moneyFormat(this.state.TotalPrice)} name="TotalPrice" readOnly={true} className="editMoneyInput" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabel">Total Tax</span>
                <input value={this.moneyFormat(this.state.TotalTax)} name="TotalTax" readOnly={true} className="editMoneyInput" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabel">Total Bill</span>
                <input value={this.moneyFormat(this.state.AmountOwing)} name="AmountOwing" readOnly={true} className="editMoneyInput" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabel">Tip Amount</span>
                <input value={this.state.TipAmountS} name="TipAmount" onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} type='tel' readOnly={false} className="editMoneyInput" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabel">Total With Tip</span>
                <input value={this.moneyFormat(this.state.TotalBill)} name="TotalBill" readOnly={true} className="editMoneyInput" />
              </div>
              <br />
              <h4>Cash Payment</h4>
              <br />
              <div id="div2" className="editField">
                <span className="editFieldLabel">Amount Paid</span>
                <input value={this.state.AmountPaidS} name="AmountPaid" onChange={evt => this.chgMoneyFldVal(evt)} onFocus={evt => this.focusMoneyFldVal(evt)} onBlur={evt => this.blurMoneyFldVal(evt)} type='tel' className="editMoneyInputB" /> {/* https://react.dev/reference/react-dom/components/input type='number' step='any' inputMode="decimal" pattern='\d'*/}
              </div>
              <br />
              <div id="div2" className="editField">
                <span className="editFieldLabel">Change Owing</span>
                <input value={this.moneyFormat(this.state.ChangeOwing)} name="ChangeOwing" readOnly={true} className="editMoneyInputB" />
              </div>
              <br />
              <div id="div2" className="editField">
                <Button id="payButton" icon="cancel" look="outline" className="smallButton" onClick={this.payCashBill} >Pay Bill With Cash</Button>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <Button className="smallButton" onClick={this.cancelPay}>Cancel</Button>
              </div>
            </div>
          </div>
        </Popup>

        <div className="managerPage" >
          <h6><span>Manager Functions</span></h6>
        </div>
      </div>
    );
  }
}

export default withRouter(ManagerPage)